import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import moment from 'moment';

import { useFormik, Form, FormikProvider } from 'formik';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  FilledInput
} from '@mui/material';

// Get the server URL from environment variable
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function EditProjectAssignment({
  assignment,
  user,
  project,
  setSelectedAssignment,
  projectList,
  employeeList,
  fetchUserProjects,
  refreshProjects,
  setShowEditAssignment
}) {
  const { enqueueSnackbar } = useSnackbar();

  const ProjectAssignmentSchema = Yup.object().shape({
    userId: Yup.string().required('Please select a user'),
    projectId: Yup.string().required('Please select a project'),
    role: Yup.string().required('Please enter the role in the project'),
    startDate: Yup.date().required(
      'Please enter the start date in the project'
    ),
    rate: Yup.number().required('Please enter the rate')
  });

  const formik = useFormik({
    initialValues: assignment._id
      ? assignment
      : {
          userId: user ? user._id : '',
          projectId: project ? project._id : '',
          role: '',
          startDate: moment().format('yyyy-MM-DD'),
          rate: 0,
          billAs: user ? user._id : ''
        },
    validationSchema: ProjectAssignmentSchema,
    onSubmit: async (values) => {
      values.startDate = moment(values.startDate).format();

      const endpoint = assignment._id
        ? `${SERVER_URL}/api/assignments/${assignment._id}`
        : `${SERVER_URL}/api/assignments`;
      const method = assignment._id ? 'PATCH' : 'POST';

      let newUserResponse = await fetch(endpoint, {
        method,
        body: JSON.stringify(values),
        headers: { 'Content-Type': 'application/json' }
      });
      newUserResponse = await newUserResponse.json();

      if (newUserResponse.success) {
        if (user) await fetchUserProjects(user._id);
        if (project) await refreshProjects();

        resetForm();
        enqueueSnackbar('Project assignment details saved successfully', {
          variant: 'success'
        });

        if (setSelectedAssignment) setSelectedAssignment(null);
        if (setShowEditAssignment) setShowEditAssignment(false);
        setShowDialog(false);
      } else {
        enqueueSnackbar(newUserResponse.data, { variant: 'error' });
      }
    }
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm
  } = formik;

  const [showDialog, setShowDialog] = useState(true);

  return (
    <div>
      <Dialog
        open={showDialog}
        onClose={(e, reason) => {
          if (reason === 'backdropClick') {
            e.stopPropagation();
            return;
          }
          resetForm();
          if (setSelectedAssignment) setSelectedAssignment(null);
          if (setShowEditAssignment) setShowEditAssignment(false);
          setShowDialog(false);
        }}
        fullWidth
        maxWidth='lg'>
        <DialogTitle>Manage Project Assignment</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid container item xs={6}>
                  {user ? (
                    <FormControl disabled variant='filled' fullWidth>
                      <InputLabel htmlFor='user-disabled'>Name</InputLabel>
                      <FilledInput
                        id='user-disabled'
                        value={`${user.firstName} ${user.lastName}`}
                        fullWidth
                      />
                    </FormControl>
                  ) : (
                    <TextField
                      size='small'
                      fullWidth
                      type='select'
                      select
                      label='Employee'
                      {...getFieldProps('userId')}
                      error={Boolean(touched.userId && errors.userId)}
                      helperText={touched.userId && errors.userId}>
                      {employeeList
                        .filter((user) => user.isActive)
                        .map((user) => (
                          <MenuItem key={user._id} value={user._id}>
                            {`${user.firstName} ${user.lastName}`}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                </Grid>
                <Grid container item xs={6}>
                  {assignment.projectName ? (
                    <FormControl disabled variant='filled' fullWidth>
                      <InputLabel htmlFor='user-disabled'>Project</InputLabel>
                      <FilledInput
                        id='user-disabled'
                        value={assignment.projectName}
                        fullWidth
                      />
                    </FormControl>
                  ) : (
                    <TextField
                      size='small'
                      fullWidth
                      type='select'
                      select
                      label='Project'
                      {...getFieldProps('projectId')}
                      error={Boolean(touched.projectId && errors.projectId)}
                      helperText={touched.projectId && errors.projectId}>
                      {projectList
                        .filter((prj) => prj.status === 'Ongoing')
                        .map((project) => (
                          <MenuItem key={project._id} value={project._id}>
                            {project.projectName}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='text'
                    label='Role'
                    autoComplete='role'
                    {...getFieldProps('role')}
                    error={Boolean(touched.role && errors.role)}
                    helperText={touched.role && errors.role}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='date'
                    label='Start Date'
                    {...getFieldProps('startDate')}
                    error={Boolean(touched.startDate && errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='number'
                    label='Rate per Hour'
                    {...getFieldProps('rate')}
                    InputProps={{
                      inputProps: { min: 0, step: 'any' },
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      )
                    }}
                    error={Boolean(touched.rate && errors.rate)}
                    helperText={touched.rate && errors.rate}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='select'
                    select
                    label='Bill As'
                    {...getFieldProps('billAs')}
                    error={Boolean(touched.userId && errors.userId)}
                    helperText={touched.userId && errors.userId}>
                    {employeeList
                      .filter((user) => user.isActive)
                      .map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                          {`${user.firstName} ${user.lastName}`}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowDialog(false);
                  if (setSelectedAssignment) setSelectedAssignment(null);
                  if (setShowEditAssignment) setShowEditAssignment(false);
                }}>
                Cancel
              </Button>
              <Button type='submit' disabled={isSubmitting}>
                Save
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
