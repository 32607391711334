import { useState } from 'react';
import moment from 'moment';

import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  Stack,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableBody,
  Tooltip,
  IconButton,
  TablePagination
} from '@mui/material';

import { visuallyHidden } from '@mui/utils';
import TableRowsIcon from '@mui/icons-material/TableRows';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import LeaveDeletionDialog from './LeaveDeletionDialog';
import EditLeaveRequest from './EditLeaveRequest';

const headCells = [
  {
    id: 'userName',
    disablePadding: true,
    label: 'Employee'
  },
  {
    id: 'startDate',
    disablePadding: false,
    label: 'Start Date'
  },
  {
    id: 'endDate',
    disablePadding: false,
    label: 'End Date'
  },
  {
    id: 'leaveType',
    disablePadding: false,
    label: 'Leave Type'
  },
  {
    id: 'daysCount',
    disablePadding: false,
    label: '# Days'
  },
  {
    id: 'reason',
    disablePadding: false,
    label: 'Reason'
  },
  {
    id: 'approvalStatus',
    disablePadding: false,
    label: 'Approval Status'
  },
  {
    id: 'approvalComments',
    disablePadding: false,
    label: 'Approval Comments'
  },
  {
    id: 'actions',
    disablePadding: false,
    label: 'Actions'
  }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function LeaveTableHeader(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function LeaveRequestTable({ leaveRequests, currentUser }) {
  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState('startDate');

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedLeave, setSelectedLeave] = useState();

  const [selectedLeaveForDelete, setSelectedLeaveForDelete] = useState();

  const [showLeaveRequestEdit, setShowLeaveRequestEdit] = useState(false);

  const rows = leaveRequests;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      {rows.length === 0 ? (
        <Stack
          direction='column'
          justifyContent='space-around'
          alignItems='center'
          spacing={1}
          sx={{ mt: 4, mb: 2 }}>
          <Typography color='error'>
            <TableRowsIcon fontSize='large' />
          </Typography>
          <Typography color='error'>No leave requests to show</Typography>
        </Stack>
      ) : (
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 1050 }}
              aria-labelledby='tableTitle'
              size='medium'
              stickyHeader>
              <LeaveTableHeader
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      _id,
                      userName,
                      startDate,
                      endDate,
                      daysCount,
                      leaveType,
                      reason,
                      approvalStatus,
                      approvalComments,
                      approvedBy
                    } = row;

                    return (
                      <TableRow hover tabIndex={-1} key={_id}>
                        <TableCell align='center'>{userName}</TableCell>
                        <TableCell align='center'>
                          {moment(startDate).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell align='center'>
                          {moment(endDate).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell align='center'>{leaveType}</TableCell>
                        <TableCell align='center'>{daysCount}</TableCell>
                        <TableCell align='center'>{reason}</TableCell>
                        <TableCell align='center'>{approvalStatus}</TableCell>
                        <TableCell align='center'>
                          {approvalStatus &&
                            `${approvedBy}: ${approvalComments}`}
                        </TableCell>

                        <TableCell align='right'>
                          {!approvalStatus && (
                            <Stack direction='row'>
                              <Tooltip title='Edit'>
                                <IconButton
                                  color='warning'
                                  onClick={() => {
                                    const leaveToEdit = { ...row };
                                    leaveToEdit.startDate = moment(
                                      leaveToEdit.startDate
                                    ).format('yyyy-MM-DD');
                                    leaveToEdit.endDate = moment(
                                      leaveToEdit.endDate
                                    ).format('yyyy-MM-DD');

                                    setSelectedLeave(leaveToEdit);
                                    setShowLeaveRequestEdit(true);
                                  }}>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title='Delete'>
                                <IconButton
                                  color='error'
                                  onClick={() => {
                                    setSelectedLeaveForDelete(row);
                                  }}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          {selectedLeave && showLeaveRequestEdit && (
            <EditLeaveRequest
              existingRequest={selectedLeave}
              user={currentUser}
              setShowLeaveRequest={setShowLeaveRequestEdit}
            />
          )}

          {selectedLeaveForDelete && (
            <LeaveDeletionDialog
              leaveRequest={selectedLeaveForDelete}
              setSelectedLeaveForDelete={setSelectedLeaveForDelete}
            />
          )}
        </Paper>
      )}
    </Box>
  );
}
