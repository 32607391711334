import { useState } from 'react';
import moment from 'moment';

import {
  Box,
  Card,
  Typography,
  Stack,
  Grid,
  Icon,
  IconButton,
  Tooltip,
  CardHeader,
  Divider
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';

import { formatHours, formatUsd } from '../../../utils/formatNumber';

import ProjectAssignmentAvatars from './ProjectAssignmentAvatars';
import ManageProject from './ManageProject';

export default function ProjectCard({
  project,
  refreshProjects,
  employeeList
}) {
  const [showEditProject, setShowEditProject] = useState(false);

  const {
    projectName,
    totalHours,
    revenue,
    clientName,
    status,
    priority,
    assignments,
    startDate,
    closeDate,
    totalBillablehours,
    totalNonBillableHours
  } = project;

  const StatusColor = new Map([
    ['Ongoing', 'success'],
    ['On Hold', 'warning'],
    ['Completed', 'error']
  ]);

  const StatusIcon = new Map([
    ['Ongoing', <PlayCircleIcon key='ongoing' />],
    ['On Hold', <PauseCircleIcon key='onHold' />],
    ['Completed', <StopCircleIcon key='completed' />]
  ]);

  const PriorityColor = new Map([
    ['Normal', 'success'],
    ['At Risk', 'error']
  ]);

  const PriorityIcon = new Map([
    ['Normal', <LibraryAddCheckIcon key='normal' />],
    ['At Risk', <ErrorOutlineIcon key='atRisk' />]
  ]);

  const openEditProject = () => {
    setShowEditProject(true);
  };

  return (
    <Card>
      <CardHeader
        action={
          <Box>
            <Tooltip title={`Status: ${status}`} placement='top'>
              <Icon sx={{ mx: 1 }} color={StatusColor.get(status)}>
                {StatusIcon.get(status)}
              </Icon>
            </Tooltip>
            <Tooltip title={`Priority: ${priority}`} placement='top'>
              <Icon sx={{ mx: 1 }} color={PriorityColor.get(priority)}>
                {PriorityIcon.get(priority)}
              </Icon>
            </Tooltip>
          </Box>
        }
        title={<Typography variant='h4'>{projectName}</Typography>}
        subheader={
          <Typography variant='caption' display='block' gutterBottom>
            Client: {clientName}
          </Typography>
        }
        sx={{ pb: 2 }}
      />

      <Box spacing={1} sx={{ ml: 2, pl: 1, pr: 1, pb: 2.5 }}>
        <Grid
          alignItems='center'
          justifyContent='flex-start'
          container
          sx={{ mb: 2 }}
          rowSpacing={2}>
          <Grid container item xs={6} direction='row'>
            <Typography variant='overline' display='block'>
              Start Date: {moment(startDate).format('DD-MM-YYYY')}
            </Typography>
          </Grid>

          {status === 'Completed' && (
            <Grid container item xs={6} direction='row'>
              <Typography variant='overline' display='block'>
                Close Date: {moment(closeDate).format('DD-MM-YYYY')}
              </Typography>
            </Grid>
          )}

          <Grid container item xs={6} direction='row'>
            <Typography variant='overline' display='block'>
              Total Revenue: {formatUsd(revenue || 0)}
            </Typography>
          </Grid>

          <Grid container item xs={6} direction='row'>
            <Tooltip
              title={`Billable: ${totalBillablehours} Non-Billable: ${totalNonBillableHours}`}
              placement='right'>
              <Typography variant='overline' display='block'>
                Hours Spent: {formatHours(totalHours || 0)}
              </Typography>
            </Tooltip>
          </Grid>

          {status !== 'Completed' && (
            <Grid container item xs={6} direction='row'>
              <Typography variant='overline' display='block'>
                No. of Resources: {assignments.length}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'>
          <Stack direction='column'>
            <Divider orientation='horizontal' flexItem>
              <Typography variant='caption' display='block'>
                Allocated Resources
              </Typography>
            </Divider>
            <ProjectAssignmentAvatars
              developers={assignments}
              project={project}
              refreshProjects={refreshProjects}
              employeeList={employeeList}
            />
          </Stack>

          <Stack alignItems='center' direction='row'>
            <Tooltip title='Edit' placement='bottom'>
              <IconButton color='success' onClick={openEditProject}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        {showEditProject === true ? (
          <ManageProject
            projectDetails={project}
            setShowAddProject={setShowEditProject}
            refreshProjects={refreshProjects}
          />
        ) : null}
      </Box>
    </Card>
  );
}
