import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

import { useSnackbar } from 'notistack';

const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function LeaveDeletionDialog({
  leaveRequest,
  setSelectedLeaveForDelete
}) {
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = async () => {
    let response = await fetch(`${SERVER_URL}/api/leaves/${leaveRequest._id}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    });

    response = await response.json();

    if (response.success) {
      enqueueSnackbar('Leave Request Deleted', {
        variant: 'success'
      });
      setSelectedLeaveForDelete();
    } else {
      enqueueSnackbar(response.data, { variant: 'error' });
    }
  };

  return (
    <Dialog
      open
      onClose={() => {
        setSelectedLeaveForDelete();
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this leave request?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setSelectedLeaveForDelete();
          }}
          color='secondary'>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='error'
          onClick={() => {
            handleDelete();
          }}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
