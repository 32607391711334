import React, { useContext } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

// layouts
import HomeLayout from './modules/HomeLayout/HomeLayout';
import LogoOnlyLayout from './utils/views/LogoOnlyLayout';

// pages
import Login from './modules/Login/Login';
import DashboardApp from './modules/Dashboard/DashboardApp';
import AdminCenter from './modules/AdminCenter/AdminCenter';
import Projects from './modules/Projects/Projects';
import Employees from './modules/Employees/Employees';
import Timesheets from './modules/Timesheets/Timesheets';
import Leaves from './modules/Leaves/Leaves';
import Holidays from './modules/Holidays/Holidays';
import NotFound from './Page404';

// contexts
import UserContext from './contexts/UserContext';

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: (
        <ProtectedComponent>
          <HomeLayout />
        </ProtectedComponent>
      ),
      children: [
        { element: <Navigate to='/dashboard/app' replace /> },
        { path: 'admin', element: <AdminCenter /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'employees', element: <Employees /> },
        { path: 'projects', element: <Projects /> },
        { path: 'timesheets', element: <Timesheets /> },
        { path: 'leaves', element: <Leaves /> },
        { path: 'holidays', element: <Holidays /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to='/404' /> }
      ]
    },
    { path: '*', element: <Navigate to='/404' replace /> }
  ]);
}

function ProtectedComponent({ children }) {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const startUrl = encodeURIComponent(location.pathname);
  return user ? children : <Navigate to={`/?startUrl=${startUrl}`} replace />;
}
