import * as React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { useSnackbar } from 'notistack';

import { visuallyHidden } from '@mui/utils';

import {
  Stack,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  IconButton,
  Tooltip
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import PersonRemove from '@mui/icons-material/PersonRemove';

import EditProjectAssignment from './EditProjectAssignment';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'projectName',
    disablePadding: true,
    label: 'Project Name'
  },
  {
    id: 'role',
    disablePadding: false,
    label: 'Role'
  },
  {
    id: 'rate',
    disablePadding: false,
    label: 'Rate (USD)'
  },
  {
    id: 'billableHours',
    disablePadding: false,
    label: 'Billable Hours'
  },
  {
    id: 'nonBillableHours',
    disablePadding: false,
    label: 'Non-Billable Hours'
  },
  {
    id: 'revenue',
    disablePadding: false,
    label: 'Revenue Generated'
  }
];

function ProjectAssignmentTableHeader(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ProjectAssignmentTableHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};

// Get the server URL from environment variable
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function EmployeeAssignments({
  projects,
  user,
  employeeList,
  projectList,
  fetchUserProjects
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [order, setOrder] = React.useState('asc');

  const [orderBy, setOrderBy] = React.useState('projectName');

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [selectedAssignment, setSelectedAssignment] = React.useState();

  const rows = projects.filter((prj) => !!prj.isActive);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const markAssignmentInactive = async (assgn) => {
    assgn.isActive = false;
    assgn.endDate = moment().format();

    let response = await fetch(`${SERVER_URL}/api/assignments/${assgn._id}`, {
      method: 'PATCH',
      body: JSON.stringify(assgn),
      headers: { 'Content-Type': 'application/json' }
    });
    response = await response.json();

    if (response.success) {
      enqueueSnackbar('Resource marked as inactive', {
        variant: 'success'
      });
      await fetchUserProjects(user._id);
    } else {
      enqueueSnackbar(response.data, { variant: 'error' });
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='subtitle1' sx={{ ml: 1 }}>
            Project Assignments
          </Typography>
          <Button
            variant='contained'
            onClick={() => {
              setSelectedAssignment({ userId: user._id });
            }}>
            Add Assignment
          </Button>
        </Stack>
        <TableContainer>
          <Table
            sx={{ minWidth: 1050 }}
            aria-labelledby='tableTitle'
            size='medium'
            stickyHeader>
            <ProjectAssignmentTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const {
                    projectName,
                    role,
                    rate,
                    billableHours,
                    nonBillableHours,
                    revenue
                  } = row;

                  const usdFormat = Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  });

                  const hoursFormat = Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2
                  });

                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell align='center'>{projectName}</TableCell>
                      <TableCell align='center'>{role}</TableCell>
                      <TableCell align='center'>
                        {usdFormat.format(rate)}
                      </TableCell>
                      <TableCell align='center'>
                        {hoursFormat.format(billableHours || 0)}
                      </TableCell>
                      <TableCell align='center'>
                        {hoursFormat.format(nonBillableHours || 0)}
                      </TableCell>
                      <TableCell align='center'>
                        {usdFormat.format(revenue || 0)}
                      </TableCell>
                      <TableCell align='right'>
                        <Stack direction='row' spacing={1}>
                          <Tooltip title='Edit Assignment'>
                            <IconButton
                              color='success'
                              onClick={() => setSelectedAssignment(row)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title='Mark Inactive'>
                            <IconButton
                              color='error'
                              onClick={async () => {
                                await markAssignmentInactive(row);
                              }}>
                              <PersonRemove />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows
                  }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {selectedAssignment && (
        <EditProjectAssignment
          assignment={selectedAssignment}
          user={user}
          projectList={projectList}
          employeeList={employeeList}
          setSelectedAssignment={setSelectedAssignment}
          fetchUserProjects={fetchUserProjects}
        />
      )}
    </Box>
  );
}
