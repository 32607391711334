import { useState, useEffect } from 'react';
import moment from 'moment';

import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  Stack,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  Button
} from '@mui/material';

import { visuallyHidden } from '@mui/utils';
import TableRowsIcon from '@mui/icons-material/TableRows';

import LeaveApprovalDialog from './LeaveApprovalDialog';

const MONTHS = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 }
];

const headCells = [
  {
    id: 'userName',
    disablePadding: true,
    label: 'Employee'
  },
  {
    id: 'startDate',
    disablePadding: false,
    label: 'Start Date'
  },
  {
    id: 'endDate',
    disablePadding: false,
    label: 'End Date'
  },
  {
    id: 'leaveType',
    disablePadding: false,
    label: 'Leave Type'
  },
  {
    id: 'daysCount',
    disablePadding: false,
    label: '# Days'
  },
  {
    id: 'reason',
    disablePadding: false,
    label: 'Reason'
  },
  {
    id: 'approvalStatus',
    disablePadding: false,
    label: 'Approval Status'
  },
  {
    id: 'approvalComments',
    disablePadding: false,
    label: 'Approval Comments'
  },
  {
    id: 'actions',
    disablePadding: false,
    label: 'Actions'
  }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => {
        if (a[orderBy] === b[orderBy]) {
          // If the values are the same, use the default sorting order
          const defaultOrder =
            headCells.find((cell) => cell.id === orderBy)?.defaultOrder ||
            'asc';
          return defaultOrder === 'desc' ? -1 : 1;
        }
        return order === 'asc' ? -1 : 1;
      };
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function LeaveApprovalTable({
  userData,
  leaveRequests,
  approvalUser
}) {
  const [usersToDisplay, setUsersToDisplay] = useState([]);

  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState('startDate');

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedLeave, setSelectedLeave] = useState();

  const [selectedMonth, setSelectedMonth] = useState(0);

  const [selectedUser, setSelectedUser] = useState(0);

  const [selectedApprovalStatus, setApprovalStatus] = useState(0);

  let finalRows = [];

  useEffect(() => {
    if (!userData || !userData.length) return;

    setUsersToDisplay(userData);
  }, [userData]);

  const rows = leaveRequests;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function filterLeaves(rows) {
    finalRows = [];

    return rows.filter((val) => {
      let returnIt = false;
      if (
        (selectedMonth === 0 ||
          moment(val.startDate).month() === selectedMonth - 1 ||
          moment(val.endDate).month() === selectedMonth - 1) &&
        (selectedUser === 0 || selectedUser === val.userId) &&
        (selectedApprovalStatus === 0 ||
          (selectedApprovalStatus === 'Unapproved' && !val.approvalStatus) ||
          selectedApprovalStatus === val.approvalStatus)
      ) {
        returnIt = true;
        finalRows.push(val);
      }

      return returnIt;
    });
  }

  function EnhancedTable(props) {
    const { usersToDisplay } = props;

    const handleChangeMonth = (event) => {
      setSelectedMonth(event.target.value);
    };

    const handleChangeuser = (event) => {
      setSelectedUser(event.target.value);
    };

    const handleChangeApprovalStatus = (event) => {
      setApprovalStatus(event.target.value);
    };
    return (
      <Stack direction='row' justifyContent='end'>
        <FormControl sx={{ m: 1, minWidth: 130, maxWidth: 130 }} size='small'>
          <InputLabel id='demo-simple-select-label'>Month</InputLabel>

          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={selectedMonth}
            label='Month'
            autoWidth
            onChange={handleChangeMonth}>
            <MenuItem value={0}>None</MenuItem>

            {MONTHS.map((month) => (
              <MenuItem key={month.value} value={month.value}>
                {month.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 150, maxWidth: 150 }} size='small'>
          <InputLabel id='demo-simple-select-autowidth-label'>
            Approval Status
          </InputLabel>
          <Select
            labelId='demo-simple-select-standard-label'
            id='demo-simple-select-standard'
            value={selectedApprovalStatus}
            onChange={handleChangeApprovalStatus}
            autoWidth
            label='Approval Status'>
            <MenuItem value={0}>None</MenuItem>
            <MenuItem value='Unapproved'>Unapproved</MenuItem>
            <MenuItem value='Approved'>Approved</MenuItem>
            <MenuItem value='Rejected'>Rejected</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 150, maxWidth: 150 }} size='small'>
          <InputLabel id='demo-simple-select-autowidth-label'>
            Employee
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={selectedUser}
            onChange={handleChangeuser}
            autoWidth
            label='Select Employee'>
            <MenuItem value={0}>All</MenuItem>
            {usersToDisplay
              .filter((user) => user.isActive)
              .map((user) => (
                <MenuItem key={user._id} value={user._id}>
                  {`${user.firstName} ${user.lastName} (${user.empId})`}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Stack>
    );
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      {rows.length === 0 ? (
        <Stack
          direction='column'
          justifyContent='space-around'
          alignItems='center'
          spacing={1}
          sx={{ mt: 4, mb: 2 }}>
          <Typography color='error'>
            <TableRowsIcon fontSize='large' />
          </Typography>
          <Typography color='error'>
            No leave requests pending for approval
          </Typography>
        </Stack>
      ) : (
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTable usersToDisplay={usersToDisplay} />
          <TableContainer>
            <Table
              sx={{ minWidth: 1050 }}
              aria-labelledby='tableTitle'
              size='medium'
              stickyHeader>
              <LeaveTableHeader
                usersToDisplay={usersToDisplay}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(filterLeaves(rows), getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      _id,
                      userName,
                      startDate,
                      endDate,
                      daysCount,
                      leaveType,
                      reason,
                      approvalStatus,
                      approvalComments,
                      approvedBy
                    } = row;

                    return (
                      <TableRow hover tabIndex={-1} key={_id}>
                        <TableCell align='center'>{userName}</TableCell>
                        <TableCell align='center'>
                          {moment(startDate).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell align='center'>
                          {moment(endDate).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell align='center'>{leaveType}</TableCell>
                        <TableCell align='center'>{daysCount}</TableCell>
                        <TableCell align='center'>{reason}</TableCell>
                        <TableCell align='center'>{approvalStatus}</TableCell>
                        <TableCell align='center'>
                          {approvalStatus &&
                            `${approvedBy}: ${approvalComments}`}
                        </TableCell>

                        <TableCell align='right'>
                          {!approvalStatus && (
                            <Button
                              variant='contained'
                              onClick={() => {
                                setSelectedLeave(row);
                              }}>
                              Approve/Reject
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={filterLeaves(rows).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          {selectedLeave && (
            <LeaveApprovalDialog
              leaveRequest={selectedLeave}
              approvalUser={approvalUser}
              setSelectedLeave={setSelectedLeave}
            />
          )}
        </Paper>
      )}
    </Box>
  );
}

function LeaveTableHeader(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
