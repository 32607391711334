import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField
} from '@mui/material';

// Get the server URL from environment variable
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function LeaveApprovalDialog({
  leaveRequest,
  approvalUser,
  setSelectedLeave
}) {
  const { enqueueSnackbar } = useSnackbar();

  const LeaveRequestSchema = Yup.object().shape({
    approvalStatus: Yup.string(),
    approvalComments: Yup.string().required('Please enter approval comments')
  });

  const formik = useFormik({
    initialValues: {
      _id: leaveRequest._id,
      approvalStatus: 'Approved',
      approvalComments: '',
      approvedBy: `${approvalUser.firstName} ${approvalUser.lastName}`
    },
    validationSchema: LeaveRequestSchema,
    onSubmit: async (values) => {
      let response = await fetch(
        `${SERVER_URL}/api/leaves/${leaveRequest._id}`,
        {
          method: 'PATCH',
          body: JSON.stringify(values),
          headers: { 'Content-Type': 'application/json' }
        }
      );

      response = await response.json();

      if (response.success) {
        enqueueSnackbar('Leave Approval Submitted', {
          variant: 'success'
        });
        setSelectedLeave();
      } else {
        enqueueSnackbar(response.data, { variant: 'error' });
      }
    }
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm
  } = formik;

  return (
    <Dialog open>
      <DialogTitle>Approve/Reject Leave Request</DialogTitle>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid container item xs={6}>
                <TextField
                  size='small'
                  fullWidth
                  type='select'
                  select
                  label='Approval Status'
                  {...getFieldProps('approvalStatus')}
                  error={Boolean(
                    touched.approvalStatus && errors.approvalStatus
                  )}
                  helperText={touched.approvalStatus && errors.approvalStatus}>
                  {['Approved', 'Rejected'].map((approval) => (
                    <MenuItem key={approval} value={approval}>
                      {approval}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid container item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  type='textarea'
                  label='Approval Comments'
                  {...getFieldProps('approvalComments')}
                  error={Boolean(
                    touched.approvalComments && errors.approvalComments
                  )}
                  helperText={
                    touched.approvalComments && errors.approvalComments
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                resetForm();
                setSelectedLeave();
              }}>
              Cancel
            </Button>
            <Button type='submit' disabled={isSubmitting}>
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
