import React, { useState } from 'react';

import { styled } from '@mui/material/styles';

import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import { Check } from '@mui/icons-material';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  'width': 240,
  'transition': theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

export default function EmployeeSearchHeader({
  filterName,
  onFilterName,
  filterShowActiveOnly,
  onFilterShowActiveOnly
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const filterMenuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <RootStyle>
      <SearchStyle
        value={filterName}
        onChange={onFilterName}
        placeholder='Search employee...'
        startAdornment={
          <InputAdornment position='start'>
            <Box
              component={Icon}
              icon={searchFill}
              sx={{ color: 'text.disabled' }}
            />
          </InputAdornment>
        }
      />

      <Tooltip title='Filter list'>
        <IconButton onClick={handleClick} id='filter-button'>
          <Icon icon={roundFilterList} />
        </IconButton>
      </Tooltip>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={filterMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem
          onClick={() => {
            onFilterShowActiveOnly(true);
          }}>
          <ListItemText>Active Only</ListItemText>
          {filterShowActiveOnly ? (
            <ListItemIcon sx={{ ml: 2 }}>
              <Check />
            </ListItemIcon>
          ) : null}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onFilterShowActiveOnly(false);
          }}>
          <ListItemText>All Employees</ListItemText>
          {!filterShowActiveOnly ? (
            <ListItemIcon sx={{ ml: 2 }}>
              <Check />
            </ListItemIcon>
          ) : null}
        </MenuItem>
      </Menu>
    </RootStyle>
  );
}
