import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Stack
} from '@mui/material';

const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function ChangePasswordDialog({ open, user, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;

    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setFieldErrors({});
    onClose();
  };

  const validateCurrentPassword = () => {
    const errors = { ...fieldErrors };

    if (!currentPassword) {
      errors.currentPassword = 'Please enter your current password';
    } else if (currentPassword !== user.password) {
      errors.currentPassword = 'Please enter your correct login password';
    } else {
      delete errors.currentPassword;
    }

    setFieldErrors(errors);
  };

  const validateNewPassword = () => {
    const errors = { ...fieldErrors };

    if (!newPassword) {
      errors.newPassword = 'Please enter a new password';
    } else {
      const passwordRegex =
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      // https://stackoverflow.com/a/40923568

      if (!passwordRegex.test(newPassword)) {
        errors.newPassword =
          'Ensure that the password has at least 1 capital letter, 1 small letter, 1 special character and a number and is at least 12 characters long';
      } else {
        delete errors.newPassword;
      }
    }

    setFieldErrors(errors);
  };

  const validateConfirmPassword = () => {
    const errors = { ...fieldErrors };

    if (!confirmPassword) {
      errors.confirmPassword = 'Please re-enter your password above';
    } else if (newPassword !== confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    } else {
      delete errors.confirmPassword;
    }

    setFieldErrors(errors);
  };

  const handleChangePassword = async () => {
    validateCurrentPassword();
    validateNewPassword();
    validateConfirmPassword();

    const values = {
      currentPassword,
      newPassword,
      confirmPassword
    };

    let response = await fetch(
      `${SERVER_URL}/api/users/${user._id}/changePassword`,
      {
        method: 'PATCH',
        body: JSON.stringify(values),
        headers: { 'Content-Type': 'application/json' }
      }
    );

    response = await response.json();

    if (response.success) {
      enqueueSnackbar('Password changed successfully', {
        variant: 'success'
      });
      onClose();
    } else {
      enqueueSnackbar('Failed to change password', { variant: 'error' });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='sm'
      fullWidth
      disableEscapeKeyDown>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <Stack spacing={1} marginTop='1em' direction='column'>
          <TextField
            autoFocus
            label='Current Password'
            type='password'
            size='small'
            fullWidth
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            onBlur={() => validateCurrentPassword()}
            error={!!fieldErrors.currentPassword}
            helperText={fieldErrors.currentPassword || ' '}
          />
          <TextField
            label='New Password'
            type='password'
            size='small'
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            onBlur={() => validateNewPassword()}
            error={!!fieldErrors.newPassword}
            helperText={fieldErrors.newPassword || ' '}
          />
          <TextField
            label='Confirm Password'
            type='password'
            size='small'
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onBlur={() => validateConfirmPassword()}
            error={!!fieldErrors.confirmPassword}
            helperText={fieldErrors.confirmPassword || ' '}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button
          onClick={handleChangePassword}
          color='primary'
          variant='contained'
          disabled={
            !!fieldErrors.currentPassword ||
            !!fieldErrors.newPassword ||
            !!fieldErrors.confirmPassword
          }>
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
}
