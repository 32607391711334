import { useEffect, useState } from 'react';

import { Card, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { Icon } from '@iconify/react';
import solutionOutlined from '@iconify/icons-ant-design/solution-outlined';

import { fShortenNumber } from '../../../utils/formatNumber';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.error.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.error.dark,
    0
  )} 0%, ${alpha(theme.palette.error.dark, 0.24)} 100%)`
}));

export default function AppUnallocatedResources({ employees }) {
  const [unallocatedCount, setUnallocatedCount] = useState(0);

  useEffect(() => {
    if (employees) {
      setUnallocatedCount(
        employees
          .filter((emp) => emp.isActive)
          .filter((emp) => !emp.assignments || !emp.assignments.length).length
      );
    }
  }, [employees]);

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={solutionOutlined} width={36} height={36} />
      </IconWrapperStyle>
      <Typography variant='h3'>{fShortenNumber(unallocatedCount)}</Typography>
      <Typography variant='subtitle2' sx={{ opacity: 0.72 }}>
        Unallocated Resources
      </Typography>
    </RootStyle>
  );
}
