import { useState } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  FilledInput
} from '@mui/material';
import Button from '@mui/material/Button';

// Get the server URL from environment variable
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function EditLeaveRequest({
  existingRequest,
  user,
  setShowLeaveRequest
}) {
  const { enqueueSnackbar } = useSnackbar();

  const LeaveRequestSchema = Yup.object().shape({
    startDate: Yup.date().required('Please enter the leave start date'),
    endDate: Yup.date()
      .required('Please enter the leave end date')
      .min(Yup.ref('startDate'), 'End Date cannot be before the Start Date'),
    reason: Yup.string().required('Please enter the reason for leave'),
    leaveType: Yup.string().required('Please enter the type of leave')
  });

  const formik = useFormik({
    initialValues: existingRequest || {
      userId: user._id,
      startDate: moment().format('yyyy-MM-DD'),
      endDate: moment().format('yyyy-MM-DD'),
      reason: '',
      leaveType: 'Full Day',
      daysCount: 1
    },
    validationSchema: LeaveRequestSchema,
    onSubmit: async (values) => {
      values.startDate = moment(values.startDate).format();
      values.endDate = moment(values.endDate).format();

      const endpoint =
        existingRequest && existingRequest._id
          ? `${SERVER_URL}/api/leaves`
          : `${SERVER_URL}/api/users/${user._id}/leaves`;
      const method = existingRequest && existingRequest._id ? 'PATCH' : 'POST';

      let submitResponse = await fetch(endpoint, {
        method,
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      submitResponse = await submitResponse.json();

      if (submitResponse.success) {
        resetForm();
        setShowLeaveRequest(false);
        setShowDialog(false);
        enqueueSnackbar('Leave Request Submitted', {
          variant: 'success'
        });
      } else {
        enqueueSnackbar(submitResponse.data, { variant: 'error' });
      }
    }
  });

  const handleDateChange = () => {
    let { startDate, endDate } = values;
    const { leaveType } = values;

    startDate = moment(startDate).startOf('day');
    endDate = moment(endDate);

    let daysCount = moment.duration(endDate.diff(startDate)).asDays() + 1;
    daysCount = daysCount < 0 ? 0 : daysCount;
    daysCount /= leaveType === 'Full Day' ? 1 : 2;

    setFieldValue('daysCount', daysCount);
  };

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue
  } = formik;

  const [showDialog, setShowDialog] = useState(true);

  return (
    <div>
      <Dialog
        open={showDialog}
        onClose={(e, reason) => {
          if (reason === 'backdropClick') {
            e.stopPropagation();
            return;
          }
          resetForm();
          setShowLeaveRequest(false);
          setShowDialog(false);
        }}
        fullWidth
        maxWidth='lg'>
        <DialogTitle>Submit Leave Request</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid container item xs={6}>
                  <FormControl disabled variant='filled' fullWidth>
                    <InputLabel htmlFor='user-disabled'>Name</InputLabel>
                    <FilledInput
                      id='user-disabled'
                      value={`${user.firstName} ${user.lastName}`}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid container item xs={6}>
                  <FormControl disabled variant='filled' fullWidth>
                    <InputLabel htmlFor='user-disabled'>No of Days</InputLabel>
                    <FilledInput
                      id='user-disabled'
                      value={values.daysCount}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='select'
                    select
                    label='Leave Type'
                    InputProps={{
                      onBlur: handleDateChange
                    }}
                    {...getFieldProps('leaveType')}
                    error={Boolean(touched.leaveType && errors.leaveType)}
                    helperText={touched.leaveType && errors.leaveType}>
                    {['Full Day', 'Half Day'].map((leaveType) => (
                      <MenuItem key={leaveType} value={leaveType}>
                        {leaveType}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='date'
                    label='Start Date'
                    InputProps={{
                      onBlur: handleDateChange
                    }}
                    {...getFieldProps('startDate')}
                    error={Boolean(touched.startDate && errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='date'
                    label='End Date'
                    InputProps={{
                      onBlur: handleDateChange
                    }}
                    {...getFieldProps('endDate')}
                    error={Boolean(touched.endDate && errors.endDate)}
                    helperText={touched.endDate && errors.endDate}
                  />
                </Grid>
                <Grid container item xs={12}>
                  <TextField
                    size='small'
                    fullWidth
                    type='textarea'
                    label='Reason for Leave'
                    {...getFieldProps('reason')}
                    error={Boolean(touched.reason && errors.reason)}
                    helperText={touched.reason && errors.reason}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowDialog(false);
                  setShowLeaveRequest(false);
                }}>
                Cancel
              </Button>
              <Button type='submit' disabled={isSubmitting}>
                Save
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
