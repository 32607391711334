import { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import {
  Button,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';

import Add from '@mui/icons-material/Add';

import Page from '../../utils/views/Page';

import UserContext from '../../contexts/UserContext';
import ManageHoliday from './components/ManageHoliday';
import EditLeaveRequest from '../Leaves/components/EditLeaveRequest';

// Get the server URL from environment variable
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

const TABLE_COLUMNS = [
  {
    id: 'startDate',
    label: 'Start Date',
    format: (val) => moment(val).format('DD MMM YYYY'),
    minWidth: '15%'
  },
  {
    id: 'endDate',
    label: 'End Date',
    format: (val) => moment(val).format('DD MMM YYYY'),
    minWidth: '15%'
  },
  { id: 'reason', label: 'Holiday Reason', minWidth: '30%' },
  { id: 'holidayType', label: 'Holiday Type', minWidth: '20%' }
];

export default function Holidays() {
  const { user } = useContext(UserContext);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [holidayList, setHolidayList] = useState([]);
  const [selectedHoliday, setSelectedHoliday] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [showApplyLeave, setShowApplyLeave] = useState(false);

  const enqueueSnackbar = useSnackbar();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isManageHolidayPermission =
    user &&
    user.permissions &&
    user.permissions.pageAccess &&
    user.permissions.pageAccess.admin;

  const refreshPageData = async () => {
    let response = await fetch(`${SERVER_URL}/api/holidays`);
    response = await response.json();

    if (response.success) {
      setHolidayList(
        response.data.sort((v1, v2) => v1.startDate - v2.startDate)
      );
    } else {
      enqueueSnackbar(response.data, { variant: 'error' });
    }
  };

  useEffect(async () => {
    await refreshPageData();
  }, []);

  return (
    <Page title='Admin Center'>
      <Container maxWidth='xl'>
        {isManageHolidayPermission && (
          <Stack direction='row-reverse' spacing={2} sx={{ padding: '1em' }}>
            <Button
              variant='contained'
              startIcon={<Add />}
              onClick={() => {
                setShowDialog(true);
              }}>
              Add Holiday
            </Button>
          </Stack>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_COLUMNS.map((col) => (
                  <TableCell
                    key={col.id}
                    align={col.align || 'left'}
                    sx={{ minWidth: col.minWidth }}>
                    {col.label}
                  </TableCell>
                ))}
                <TableCell align='center' sx={{ minWidth: '20%' }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {holidayList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow hover key={row._id}>
                    {TABLE_COLUMNS.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align || 'left'}
                          sx={{ minWidth: column.minWidth }}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    <TableCell align='center' sx={{ minWidth: '20%' }}>
                      <Stack
                        spacing={1}
                        direction='row'
                        justifyContent='center'>
                        <Button
                          variant='contained'
                          onClick={() => {
                            setShowApplyLeave(true);
                            setSelectedHoliday(row);
                          }}
                          disabled={moment(row.startDate).isBefore()}>
                          Apply Leave
                        </Button>
                        {isManageHolidayPermission && (
                          <Button
                            variant='outlined'
                            onClick={() => {
                              row.startDate = moment(row.startDate).format(
                                'yyyy-MM-DD'
                              );
                              row.endDate = moment(row.endDate).format(
                                'yyyy-MM-DD'
                              );
                              setShowDialog(true);
                              setSelectedHoliday(row);
                            }}
                            disabled={moment(row.startDate).isBefore()}>
                            Edit Holiday
                          </Button>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={holidayList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Container>

      {showDialog && (
        <ManageHoliday
          holiday={selectedHoliday}
          setSelectedHoliday={setSelectedHoliday}
          setShowDialog={setShowDialog}
          refreshData={refreshPageData}
        />
      )}

      {showApplyLeave && (
        <EditLeaveRequest
          user={user}
          existingRequest={{
            startDate: moment(selectedHoliday.startDate).format('yyyy-MM-DD'),
            endDate: moment(selectedHoliday.endDate).format('yyyy-MM-DD'),
            userId: user._id,
            reason: selectedHoliday.reason,
            leaveType: 'Full Day',
            daysCount:
              moment(selectedHoliday.endDate).diff(
                moment(selectedHoliday.startDate),
                'days'
              ) + 1
          }}
          setShowLeaveRequest={setShowApplyLeave}
        />
      )}
    </Page>
  );
}
