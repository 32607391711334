import { forwardRef, useContext, useEffect, useState } from 'react';
import { keyBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/system';

import { InputUnstyled } from '@mui/base';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Button,
  Checkbox,
  Typography,
  IconButton,
  Tooltip,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { Cancel } from '@mui/icons-material';

import UserContext from '../../../contexts/UserContext';

const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  600: '#0072E5'
};

const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027'
};
const StyledInputElement = styled('input')(
  ({ theme }) => `
	width: 100%;
	font-size: 0.875rem;
	color: ${theme.palette.mode === 'dark' ? '#000' : '#000'};
	background: ${theme.palette.mode === 'dark' ? '#fff' : '#fff'};
	border: 1px solid ${grey[400]};
    border-radius: 4px;
	padding: 7px 7px;

	&:hover {
	  background: #fff;
	  border-color: ${grey[200]};
	}

	&:focus {
	  outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
	}
  `
);

const CustomInput = forwardRef((props, ref) => (
  <InputUnstyled
    components={{ Input: StyledInputElement }}
    {...props}
    ref={ref}
  />
));

// Get the server URL from environment variable
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function TimesheetTable({
  projectList,
  rowsToDisplay,
  entryDate
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);

  const [rows, setRows] = useState([]);

  const [userProjects, setUserProjects] = useState([]);

  const [selectedProject, setSelectedProject] = useState('');

  const [rowIndexEditable, setRowIndexEditable] = useState();

  const [draftValues, setDraftValues] = useState();

  const [disableActions, setDisableActions] = useState(false);

  useEffect(() => {
    setRows(rowsToDisplay || []);
  }, [rowsToDisplay]);

  useEffect(() => {
    setUserProjects(projectList || []);
  }, [projectList]);

  const saveTimeEntry = async (val, index) => {
    setDisableActions(true);
    val.entryDate = val.entryDate || entryDate;
    val.userId = val.userId || user._id;
    val.isBillable = !!val.isBillable;

    let response = await fetch(
      `${SERVER_URL}/api/assignments/${val.assignmentId}/timeEntries`,
      {
        method: val._id ? 'PATCH' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(val)
      }
    );
    response = await response.json();

    if (response.success) {
      setRows(
        rows.map((row, rIndex) => (rIndex === index ? response.data : row))
      );
      enqueueSnackbar('Time entry saved', { variant: 'success' });
    } else {
      enqueueSnackbar(response.data, { variant: 'error' });
    }

    setDisableActions(false);
  };

  const handleDeleteRow = async (index, val) => {
    setDisableActions(true);

    if (val._id) {
      let response = await fetch(`${SERVER_URL}/api/timeEntries/${val._id}`, {
        method: 'DELETE'
      });
      response = await response.json();

      if (response.success) {
        enqueueSnackbar('Time entry deleted', { variant: 'success' });
        setRows(() => {
          const tempRows = Object.assign([], rows);
          tempRows.splice(index, 1);
          return tempRows;
        });
        setRowIndexEditable();
      } else {
        enqueueSnackbar(response.data, { variant: 'error' });
      }
    } else {
      setRows(() => {
        const tempRows = Object.assign([], rows);
        tempRows.splice(index, 1);
        return tempRows;
      });
      setRowIndexEditable();
    }

    setDisableActions(false);
  };

  const handleAddRow = () => {
    setRows((prevRows) => [...prevRows, {}]);
    setRowIndexEditable(rows.length);
  };

  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      sx={{ p: 1 }}>
      {rows.length !== 0 ? (
        <TableContainer component={Paper} sx={{ border: '1px solid black' }}>
          <Table sx={{ minWidth: '100%' }} size='small'>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ borderBottom: '1.5px solid #888' }}
                  width='20%'
                  align='center'>
                  Project Name
                </TableCell>
                <TableCell
                  sx={{ borderBottom: '1.5px solid #888' }}
                  width='5%'
                  align='center'>
                  Hours
                </TableCell>
                <TableCell
                  sx={{ borderBottom: '1.5px solid #888' }}
                  width='5%'
                  align='center'>
                  Billable?
                </TableCell>
                <TableCell
                  sx={{ borderBottom: '1.5px solid #888' }}
                  width='30%'
                  align='center'>
                  Notes
                </TableCell>
                <TableCell
                  sx={{ borderBottom: '1.5px solid #888' }}
                  width='30%'
                  align='center'>
                  Status
                </TableCell>
                <TableCell
                  sx={{ borderBottom: '1.5px solid #888' }}
                  width='15%'
                  align='center'>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((val, index) => {
                const isNonEditable = !!val.isApproved;
                const isRejected = !!val.isRejected;
                return (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}>
                    {/* Project name */}
                    <TableCell
                      sx={{
                        borderBottom: '1px solid #ccc'
                      }}
                      width='20%'
                      align='left'>
                      {isNonEditable || rowIndexEditable !== index ? (
                        <Typography
                          align='left'
                          variant='subtitle2'
                          component='div'>
                          {keyBy(userProjects, 'value')[val.assignmentId] &&
                            keyBy(userProjects, 'value')[val.assignmentId]
                              .label}
                        </Typography>
                      ) : (
                        rowIndexEditable === index && (
                          <FormControl size='small' sx={{ width: '70%' }}>
                            <InputLabel id='demo-simple-select-label'>
                              Project
                            </InputLabel>
                            <Select
                              id='demo-simple-select'
                              defaultValue={val.assignmentId}
                              value={selectedProject}
                              label='Project'
                              onChange={(e) => {
                                val.assignmentId = e.target.value;
                                setSelectedProject(e.target.value);
                              }}>
                              {userProjects
                                .filter((prj) => prj.isOngoing)
                                .map((prj) => (
                                  <MenuItem key={prj.value} value={prj.value}>
                                    {prj.label}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        )
                      )}
                    </TableCell>

                    {/* Hour Spent */}
                    <TableCell
                      sx={{
                        borderBottom: '1px solid #ccc'
                      }}
                      width='5%'
                      align='center'>
                      {isNonEditable || rowIndexEditable !== index ? (
                        <Typography
                          align='center'
                          variant='subtitle2'
                          component='div'>
                          {val.hours}
                        </Typography>
                      ) : (
                        rowIndexEditable === index && (
                          <CustomInput
                            size='small'
                            aria-label='Demo input'
                            defaultValue={val.hours}
                            type='number'
                            placeholder='0'
                            onChange={(e) => (val.hours = e.target.value)}
                          />
                        )
                      )}
                    </TableCell>

                    {/* Is Billable */}
                    <TableCell
                      sx={{
                        borderBottom: '1px solid #ccc'
                      }}
                      width='5%'
                      align='center'>
                      {isNonEditable || rowIndexEditable !== index ? (
                        <Typography
                          align='center'
                          variant='subtitle2'
                          component='div'>
                          {val.isBillable ? (
                            <CheckIcon color='success' defaultChecked />
                          ) : (
                            <CloseIcon color='error' />
                          )}
                        </Typography>
                      ) : (
                        rowIndexEditable === index && (
                          <Checkbox
                            defaultChecked={val.isBillable}
                            onChange={(e) =>
                              (val.isBillable = e.target.checked)
                            }
                          />
                        )
                      )}
                    </TableCell>

                    {/* Notes */}
                    <TableCell
                      sx={{
                        borderBottom: '1px solid #ccc'
                      }}
                      width='30%'
                      align='left'>
                      {isNonEditable || rowIndexEditable !== index ? (
                        <Typography
                          align='left'
                          variant='subtitle2'
                          component='div'>
                          {val.notes}
                        </Typography>
                      ) : (
                        rowIndexEditable === index && (
                          <CustomInput
                            size='small'
                            aria-label='Demo input'
                            placeholder='Enter work details...'
                            onChange={(e) => (val.notes = e.target.value)}
                            defaultValue={val.notes}
                          />
                        )
                      )}
                    </TableCell>

                    {/* Status */}
                    <TableCell
                      sx={{
                        borderBottom: '1px solid #ccc'
                      }}
                      width='30%'
                      align='center'>
                      {isNonEditable ? (
                        <Typography
                          align='center'
                          variant='subtitle2'
                          component='div'>
                          Approved
                        </Typography>
                      ) : null}
                      {isRejected ? (
                        <Typography
                          align='center'
                          variant='subtitle2'
                          component='div'>
                          Rejected: {val.rejectionReason}
                        </Typography>
                      ) : null}
                    </TableCell>

                    {/* Actions */}
                    <TableCell
                      sx={{
                        borderBottom: '1px solid #ccc'
                      }}
                      width='10%'
                      align='center'>
                      {!isNonEditable && rowIndexEditable !== index ? (
                        <Stack direction='row'>
                          <Tooltip title='Edit' placement='bottom'>
                            <span>
                              <IconButton
                                disabled={disableActions}
                                color='warning'
                                onClick={() => {
                                  setSelectedProject(val.projectName);
                                  setRowIndexEditable(index);
                                  setDraftValues({
                                    ...val
                                  });
                                }}>
                                <EditIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title='Delete' placement='bottom'>
                            <span>
                              <IconButton
                                disabled={disableActions}
                                color='error'
                                onClick={() => {
                                  handleDeleteRow(index, val);
                                }}>
                                <DeleteIcon color='error' />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Stack>
                      ) : (
                        rowIndexEditable === index && (
                          <Stack direction='row'>
                            <Tooltip title='Save' placement='bottom'>
                              <span>
                                <IconButton
                                  disabled={disableActions}
                                  color='success'
                                  onClick={() => {
                                    if (
                                      val.assignmentId &&
                                      val.hours &&
                                      val.notes
                                    ) {
                                      saveTimeEntry(val, index);
                                      setRowIndexEditable();
                                      setDraftValues();
                                      setSelectedProject('');
                                    } else {
                                      enqueueSnackbar(
                                        'Please enter all the values',
                                        {
                                          variant: 'info'
                                        }
                                      );
                                    }
                                  }}>
                                  <CheckIcon color='success' />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip title='Cancel' placement='bottom'>
                              <span>
                                <IconButton
                                  disabled={disableActions}
                                  color='error'
                                  onClick={() => {
                                    const currentRows = rows;
                                    currentRows.splice(
                                      index,
                                      1,
                                      draftValues || {}
                                    );
                                    setRows(currentRows);
                                    setRowIndexEditable();
                                  }}>
                                  <Cancel color='error' />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </Stack>
                        )
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant='subtitle1' sx={{ width: '100%' }}>
          Select a date to view entered timesheets or add new record
        </Typography>
      )}

      <Stack
        direction='row'
        justifyContent='flex-end'
        sx={{ my: '0.5rem', width: '100%' }}>
        <Button
          variant='contained'
          onClick={handleAddRow}
          startIcon={<AddIcon />}>
          Add new Row
        </Button>
      </Stack>
    </Stack>
  );
}
