import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik, Form, FormikProvider } from 'formik';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField
} from '@mui/material';

// Get the server URL from environment variable
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function ManageHoliday({
  holiday,
  setSelectedHoliday,
  setShowDialog,
  refreshData
}) {
  const { enqueueSnackbar } = useSnackbar();

  const HolidaySchema = Yup.object().shape({
    startDate: Yup.date().required('Please enter the end date'),
    endDate: Yup.date()
      .required('Please enter the end date')
      .min(Yup.ref('startDate'), 'End Date cannot be before the Start Date'),
    reason: Yup.string().required('Please enter the holiday reason'),
    holidayType: Yup.string()
  });

  const formik = useFormik({
    initialValues: holiday || {
      startDate: moment().format('yyyy-MM-DD'),
      endDate: moment().format('yyyy-MM-DD'),
      holidayType: '',
      reason: ''
    },
    validationSchema: HolidaySchema,
    onSubmit: async (values) => {
      values.startDate = moment(values.startDate).format();
      values.endDate = moment(values.endDate).format();

      const endpoint = `${SERVER_URL}/api/holidays`;
      const method = holiday ? 'PATCH' : 'POST';

      let submitResponse = await fetch(endpoint, {
        method,
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      submitResponse = await submitResponse.json();

      if (submitResponse.success) {
        await refreshData();
        setShowDialog(false);
        enqueueSnackbar('Holiday Record Submitted', {
          variant: 'success'
        });
      } else {
        enqueueSnackbar(submitResponse.data, { variant: 'error' });
      }
    }
  });

  const deleteHoliday = async (holiday) => {
    const endpoint = `${SERVER_URL}/api/holidays/${holiday._id}`;
    const method = 'DELETE';

    let submitResponse = await fetch(endpoint, {
      method,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    submitResponse = await submitResponse.json();

    if (submitResponse.success) {
      await refreshData();
      setShowDialog(false);
      enqueueSnackbar('Holiday Record Deleted', {
        variant: 'success'
      });
    } else {
      enqueueSnackbar(submitResponse.data, { variant: 'error' });
    }
  };

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm
  } = formik;

  return (
    <Dialog
      open
      onClose={(e, reason) => {
        if (reason === 'backdropClick') {
          e.stopPropagation();
          return;
        }
        resetForm();
        setSelectedHoliday();
        setShowDialog(false);
      }}
      fullWidth
      maxWidth='lg'>
      <DialogTitle>Add/Edit Holiday Record</DialogTitle>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid container item xs={6}>
                <TextField
                  size='small'
                  fullWidth
                  type='date'
                  label='Start Date'
                  {...getFieldProps('startDate')}
                  error={Boolean(touched.startDate && errors.startDate)}
                  helperText={touched.startDate && errors.startDate}
                />
              </Grid>
              <Grid container item xs={6}>
                <TextField
                  size='small'
                  fullWidth
                  type='date'
                  label='End Date'
                  {...getFieldProps('endDate')}
                  error={Boolean(touched.endDate && errors.endDate)}
                  helperText={touched.endDate && errors.endDate}
                />
              </Grid>
              <Grid container item xs={6}>
                <TextField
                  size='small'
                  fullWidth
                  type='select'
                  select
                  label='Holiday Type'
                  {...getFieldProps('holidayType')}
                  error={Boolean(touched.holidayType && errors.holidayType)}
                  helperText={touched.holidayType && errors.holidayType}>
                  {['Fixed Holiday', 'Floating Holiday'].map((holidayType) => (
                    <MenuItem key={holidayType} value={holidayType}>
                      {holidayType}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid container item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  type='text'
                  label='Reason'
                  {...getFieldProps('reason')}
                  error={Boolean(touched.reason && errors.reason)}
                  helperText={touched.reason && errors.reason}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setSelectedHoliday();
                setShowDialog(false);
              }}>
              Cancel
            </Button>
            <Button type='submit' disabled={isSubmitting}>
              Save
            </Button>
            {holiday && holiday._id && (
              <Button
                color='error'
                onClick={async () => {
                  await deleteHoliday(holiday);
                  setSelectedHoliday();
                  setShowDialog(false);
                }}>
                Delete
              </Button>
            )}
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
