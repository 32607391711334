import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { Tab, Tabs, Button, Box, Stack, Typography } from '@mui/material';

import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';

import EditLeaveRequest from './components/EditLeaveRequest';
import LeaveRequestTable from './components/LeaveRequestTable';

import UserContext from '../../contexts/UserContext';
import AppDataContext from '../../contexts/AppContext';

import Page from '../../utils/views/Page';
import LeaveApprovalTable from './components/LeaveApprovalTable';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function Leaves() {
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);

  const [showLeaveRequest, setShowLeaveRequest] = useState();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title='Leaves'>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label='My Leaves' {...a11yProps(0)} />
            <Tab label='Leaves For Approval' {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Stack
            direction='column-reverse'
            justifyContent='center'
            alignItems='flex-end'
            mb={3}>
            <Button
              variant='contained'
              startIcon={<Icon icon={plusFill} />}
              onClick={() => {
                setShowLeaveRequest(true);
              }}>
              Add Leave Request
            </Button>
          </Stack>
          {appData.leaves && user && (
            <LeaveRequestTable
              leaveRequests={appData.leaves.filter(
                (row) => user._id === row.userId
              )}
              currentUser={user}
            />
          )}
          {showLeaveRequest && (
            <EditLeaveRequest
              user={user}
              setShowLeaveRequest={setShowLeaveRequest}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {appData.leaves && user && (
            <LeaveApprovalTable
              userData={appData.users}
              leaveRequests={appData.leaves.filter((row) =>
                user.reportingUsers.includes(row.userId)
              )}
              approvalUser={user}
            />
          )}
        </TabPanel>
      </Box>
    </Page>
  );
}
