// utilities
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// routes
import Router from './routes';

// theme
import GlobalStyles from './theme/globalStyles';

// components
import { prefetchData } from './utils/networkUtils';
import ScrollToTop from './utils/views/ScrollToTop';
import { BaseOptionChartStyle } from './utils/charts/BaseOptionChart';

// contexts
import UserContext from './contexts/UserContext';
import AppDataContext from './contexts/AppContext';

// Get the server URL from environment variable
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function App() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(async () => {
    const authUserId = Cookies.get('auth-cookie');

    if (!authUserId) return;

    let userResponse = await fetch(`${SERVER_URL}/api/users/${authUserId}`);
    userResponse = await userResponse.json();

    if (userResponse.success) {
      setUser(userResponse.data);

      await prefetchData(
        (response) => {
          setAppData(response);
        },
        (response) => {
          const { data } = response;
          enqueueSnackbar(data, { variant: 'error' });
        }
      );

      let nextPage = location.pathname;

      // Check if there is a start url param
      const startUrl = searchParams.get('startUrl');
      nextPage = startUrl || nextPage;

      if (
        userResponse.data &&
        userResponse.data.permissions &&
        userResponse.data.permissions.pageAccess &&
        userResponse.data.permissions.pageAccess.dashboard === true
      ) {
        nextPage = nextPage === '/' ? '/dashboard/app' : nextPage;

        navigate(nextPage, { replace: true });
      } else {
        navigate('/dashboard/timesheets');
      }
    } else {
      enqueueSnackbar(userResponse.data, { variant: 'error' });
    }
  }, []);

  const [user, setUser] = useState();

  const [appData, setAppData] = useState();

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />

      <AppDataContext.Provider value={{ appData, setAppData }}>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <Router />
      </AppDataContext.Provider>
    </UserContext.Provider>
  );
}
