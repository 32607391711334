import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material';

const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function TimesheetRejectionDialog({
  timeEntry,
  setSelectedTimeEntry,
  rejectedByUser
}) {
  const { enqueueSnackbar } = useSnackbar();

  const RejectionSchema = Yup.object().shape({
    rejectionReason: Yup.string().required('Please enter rejection reason')
  });

  const formik = useFormik({
    initialValues: {
      _id: timeEntry._id,
      rejectionReason: '',
      rejectedBy: `${rejectedByUser.firstName} ${rejectedByUser.lastName}`
    },
    validationSchema: RejectionSchema,
    onSubmit: async (values) => {
      let response = await fetch(
        `${SERVER_URL}/api/timeEntries/${timeEntry._id}`,
        {
          method: 'PATCH',
          body: JSON.stringify(values),
          headers: { 'Content-Type': 'application/json' }
        }
      );

      response = await response.json();

      if (response.success) {
        enqueueSnackbar('Timesheet Rejected', {
          variant: 'success'
        });
        setSelectedTimeEntry();
      } else {
        enqueueSnackbar(response.data, { variant: 'error' });
      }
    }
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm
  } = formik;

  return (
    <Dialog open maxWidth='sm' fullWidth>
      <DialogTitle>Reject Time Entry</DialogTitle>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Grid rowSpacing={2} columnSpacing={2}>
              <Grid item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  type='textarea'
                  label='Rejection Reason'
                  {...getFieldProps('rejectionReason')}
                  error={Boolean(
                    touched.rejectionReason && errors.rejectionReason
                  )}
                  helperText={touched.rejectionReason && errors.rejectionReason}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                resetForm();
                setSelectedTimeEntry();
              }}>
              Cancel
            </Button>
            <Button
              type='submit'
              variant='contained'
              color='error'
              disabled={isSubmitting}>
              Reject
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
