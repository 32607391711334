import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

const usdFormat = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const hoursFormat = Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2
});

export function formatUsd(number) {
  return usdFormat.format(number);
}

export function formatHours(number) {
  return hoursFormat.format(number);
}

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}
