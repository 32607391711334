import { useState, useEffect } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { filter } from 'lodash';

import {
  Box,
  CardContent,
  Card,
  Stack,
  Button,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';

import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';

import Page from '../../utils/views/Page';

import Scrollbar from '../../utils/views/Scrollbar';
import SearchNotFound from '../../utils/SearchNotFound';
import EmployeeSearchHeader from './components/EmployeeSearchHeader';
import ManageEmployeeDetails from './components/ManageEmployeeDetails';
import EmployeeListCard from './components/EmployeeListCard';

// Get the server URL from environment variable
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, showActive) {
  const activeFiltered = showActive ? filter(array, 'isActive') : array;

  const stabilizedThis = activeFiltered.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(
      activeFiltered,
      (_user) =>
        (_user.firstName + _user.lastName + _user.empId)
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }

  return stabilizedThis.map((el) => el[0]);
}

export default function Employees() {
  const { enqueueSnackbar } = useSnackbar();

  const orderBy = 'empId';
  const order = 'asc';

  const [employeeList, setEmployeeList] = useState([]);

  const [allProjects, setAllProjects] = useState([]);

  const [page, setPage] = useState(0);

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterShowActiveOnly, setFilterShowActiveOnly] = useState(true);

  const [showNewEmployee, setShowNewEmployee] = useState(false);

  const [selectedUser, setSelectedUser] = useState();

  useEffect(async () => {
    await refreshEmployees();

    let projectsResponse = await fetch(`${SERVER_URL}/api/projects`);
    projectsResponse = await projectsResponse.json();

    if (projectsResponse.success) {
      setAllProjects(projectsResponse.data);
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleFilterShowActiveChange = (value) => {
    setFilterShowActiveOnly(value);
  };

  const refreshEmployees = async () => {
    let empResponse = await fetch(`${SERVER_URL}/api/users`);
    empResponse = await empResponse.json();

    if (empResponse.success) {
      const empList = empResponse.data.map((emp) => {
        emp.dob = moment(emp.dob).format('yyyy-MM-DD');
        emp.dateOfJoining = moment(emp.dateOfJoining).format('yyyy-MM-DD');

        return emp;
      });

      setEmployeeList(empList);
    } else {
      enqueueSnackbar(empResponse.data, { variant: 'error' });
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - employeeList.length) : 0;

  const filteredEmployees = applySortFilter(
    employeeList,
    getComparator(order, orderBy),
    filterName,
    filterShowActiveOnly
  );

  const isEmpNotFound = filteredEmployees.length === 0;

  return (
    <Page title='Employees'>
      <Container>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={3}>
          <Typography variant='h4' gutterBottom>
            Employees
          </Typography>
          <Button
            variant='contained'
            startIcon={<Icon icon={plusFill} />}
            onClick={() => {
              setShowNewEmployee(true);
            }}>
            New Employee
          </Button>
        </Stack>

        {employeeList.length !== 0 ? (
          <Card>
            <EmployeeSearchHeader
              filterName={filterName}
              onFilterName={handleFilterByName}
              filterShowActiveOnly={filterShowActiveOnly}
              onFilterShowActiveOnly={handleFilterShowActiveChange}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Box sx={{ flexGrow: 1 }}>
                  {filteredEmployees
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <EmployeeListCard
                        key={row._id}
                        row={row}
                        setSelectedUser={setSelectedUser}
                        employeeList={employeeList}
                        projectList={allProjects}
                        refreshEmployees={refreshEmployees}
                      />
                    ))}
                  {emptyRows > 0 && (
                    <Box style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={3} />
                    </Box>
                  )}

                  {isEmpNotFound && (
                    <Card>
                      <Card>
                        <CardContent align='center'>
                          <SearchNotFound searchQuery={filterName} />
                        </CardContent>
                      </Card>
                    </Card>
                  )}
                </Box>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component='div'
              count={filteredEmployees.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        ) : null}
      </Container>

      {(showNewEmployee || selectedUser) && (
        <ManageEmployeeDetails
          setShowNewEmployee={setShowNewEmployee}
          setSelectedUser={setSelectedUser}
          employeeDetails={selectedUser}
          forceRefresh={refreshEmployees}
        />
      )}
    </Page>
  );
}
