import { useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { alpha, styled } from '@mui/material/styles';

import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Refresh } from '@mui/icons-material';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';

import { MHidden } from '../../../utils/@material-extend';

import AccountPopover from './AccountPopover';

import UserContext from '../../../contexts/UserContext';
import AppDataContext from '../../../contexts/AppContext';

import { prefetchData } from '../../../utils/networkUtils';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const { setAppData } = useContext(AppDataContext);

  const [isLoading, setIsLoading] = useState(false);

  const forceDataRefresh = async () => {
    setIsLoading(true);

    await prefetchData(
      (response) => {
        setAppData(response);
        enqueueSnackbar('All data refreshed successfully', {
          variant: 'success'
        });
      },
      (response) => {
        const { data } = response;
        enqueueSnackbar(data, { variant: 'error' });
      }
    );

    setIsLoading(false);
  };

  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width='lgUp'>
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        {/* <Searchbar /> */}
        <Typography variant='h4' color='WindowText'>
          Hi {user.firstName}, Welcome back
        </Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction='row'
          alignItems='center'
          spacing={{ xs: 0.5, sm: 1.5 }}>
          {!isLoading ? (
            <Button
              variant='outlined'
              onClick={forceDataRefresh}
              startIcon={<Refresh />}
              style={{ marginRight: '1.5rem' }}>
              Reload Data
            </Button>
          ) : (
            <LoadingButton
              style={{ marginRight: '1.5rem' }}
              loading
              variant='outlined'>
              Reload Data
            </LoadingButton>
          )}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
