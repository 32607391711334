import { keyBy } from 'lodash';

const MONGO_ID = '_id';

// Get the server URL from environment variable
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

// Method to prefetch the complete application data
export const prefetchData = async (success, failure) => {
  let data = await fetch(`${SERVER_URL}/api/data`);
  data = await data.json();

  if (data.success) {
    let { users, projects, assignments } = data.data;
    const { leaves, timeEntries } = data.data;

    const timeEntriesById = keyBy(timeEntries, MONGO_ID);

    // Update the assignment relationships
    assignments = assignments.map((assgn) => {
      assgn.timeEntries = assgn.timeEntries.reduce(
        (timeEntries, timeEntryId) => {
          if (timeEntriesById[timeEntryId]) {
            timeEntries.push(timeEntriesById[timeEntryId]);
          }
          return timeEntries;
        },
        []
      );

      // Update the date fields to required format
      // assgn.startDate = moment(assgn.startDate).format('YYYY-MM-DD');
      // assgn.endDate = moment(assgn.endDate).format('YYYY-MM-DD');

      return assgn;
    });
    const assignmentsById = keyBy(assignments, MONGO_ID);
    const leavesById = keyBy(leaves, MONGO_ID);

    // Update the user relationships
    users = users.map((user) => {
      // Update the user assignments with record values
      user.assignments = user.assignments.map(
        (assgnId) => assignmentsById[assgnId]
      );

      // Update the leave with record values
      user.leaves = user.leaves.map((leaveId) => leavesById[leaveId]);

      // Update the date fields to required format
      user.dob = user.dob || '2000-01-01';

      return user;
    });

    // Update the project relationships
    projects = projects.map((project) => {
      // Update the project assignments with record values
      project.assignments = project.assignments.map(
        (assgn) => assignmentsById[assgn[MONGO_ID]]
      );

      return project;
    });

    success(data.data);
  } else {
    failure(data);
  }
};
