import { useState, useEffect } from 'react';

import { Card, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { Icon } from '@iconify/react';
import dollarCircleFilled from '@iconify/icons-ant-design/dollar-circle-filled';

import { formatUsd } from '../../../utils/formatNumber';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.dark,
    0
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`
}));

// ----------------------------------------------------------------------

export default function AppCurrentRevenue({ projects }) {
  const [revenueTotal, setRevenueTotal] = useState(0);

  useEffect(() => {
    if (projects) {
      setRevenueTotal(
        projects.reduce((prev, current) => prev + current.revenue, 0)
      );
    }
  }, [projects]);

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={dollarCircleFilled} width={36} height={36} />
      </IconWrapperStyle>
      <Typography variant='h3'>{formatUsd(revenueTotal || 0)}</Typography>
      <Typography variant='subtitle2' sx={{ opacity: 0.72 }}>
        Current Revenue
      </Typography>
    </RootStyle>
  );
}
