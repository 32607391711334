import { useState } from 'react';

import { Avatar, AvatarGroup, IconButton, Stack, Tooltip } from '@mui/material';

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import EditProjectAssignment from '../../Employees/components/EditProjectAssignment';

export default function ProjectAssignmentAvatars({
  developers,
  project,
  employeeList,
  refreshProjects
}) {
  const [showEditAssignment, setShowEditAssignment] = useState(false);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(dev) {
    const name = `${dev.firstName} ${dev.lastName}`;
    return {
      sx: {
        bgcolor: stringToColor(name)
      },
      children: `${dev.firstName.charAt(0)}${dev.lastName.charAt(0)}`
    };
  }

  return (
    <div>
      <Stack direction='row' spacing={1}>
        <AvatarGroup total={developers.length}>
          {developers.map((dev, index) => (
            <Tooltip
              title={`${dev.firstName} ${dev.lastName}: ${dev.role}`}
              key={index}>
              <Avatar {...stringAvatar(dev)} src={dev.photoUrl || 'nosrc'} />
            </Tooltip>
          ))}
        </AvatarGroup>

        {project.status === 'Ongoing' && (
          <Tooltip title='Add Resource'>
            <IconButton
              color='primary'
              size='large'
              onClick={() => {
                setShowEditAssignment(true);
              }}>
              <PersonAddAlt1Icon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      {showEditAssignment && (
        <EditProjectAssignment
          assignment={{
            projectId: project._id,
            projectName: project.projectName
          }}
          project={project}
          employeeList={employeeList}
          refreshProjects={refreshProjects}
          setShowEditAssignment={setShowEditAssignment}
        />
      )}
    </div>
  );
}
