import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import clockOutline from '@iconify/icons-eva/clock-outline';
import imageFill from '@iconify/icons-eva/image-fill';
import globeFill from '@iconify/icons-eva/globe-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import calendarOutline from '@iconify/icons-eva/calendar-outline';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Admin Center',
    path: '/dashboard/admin',
    icon: getIcon(settings2Fill),
    pageName: 'admin'
  },
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill),
    pageName: 'dashboard'
  },
  {
    title: 'Employees',
    path: '/dashboard/employees',
    icon: getIcon(peopleFill),
    pageName: 'employees'
  },
  {
    title: 'Projects',
    path: '/dashboard/projects',
    icon: getIcon(globeFill),
    pageName: 'projects'
  },
  {
    title: 'Timesheets',
    path: '/dashboard/timesheets',
    icon: getIcon(clockOutline),
    pageName: 'timesheets'
  },
  {
    title: 'Leaves',
    path: '/dashboard/leaves',
    icon: getIcon(imageFill),
    pageName: 'leaves'
  },
  {
    title: 'Holiday Calendar',
    path: '/dashboard/holidays',
    icon: getIcon(calendarOutline),
    pageName: 'holidays'
  }
];

export default sidebarConfig;
