import moment from 'moment';

import {
  Card,
  Typography,
  CardHeader,
  CardContent,
  Tooltip
} from '@mui/material';

import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineDot
} from '@mui/lab';

function LeaveEntry({ item, isLast }) {
  const { leaveType, userName, startDate, endDate, daysCount } = item;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <Tooltip title={leaveType}>
          <TimelineDot
            sx={{
              bgcolor: leaveType === 'Full Day' ? 'primary.main' : 'info.main'
            }}
          />
        </Tooltip>
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant='subtitle2'>{`${userName}, ${daysCount} ${
          daysCount > 1 ? 'days' : 'day'
        }`}</Typography>
        <Typography variant='caption' sx={{ color: 'text.secondary' }}>
          {`${moment(startDate).format('DD MMM YYYY')} to ${moment(
            endDate
          ).format('DD MMM YYYY')}`}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

export default function AppUpcomingLeaves({ leaves }) {
  const leavesToDisplay = (leaves || [])
    .filter((leave) => leave.approvalStatus === 'Approved')
    .filter((leave) => {
      const momentToday = moment();
      const momentStartDate = moment(leave.startDate).startOf('day');
      const momentFiveDays = moment().add(5, 'days').endOf('day');
      const momentEndDate = moment(leave.endDate).endOf('day');

      const isStartDateValid = momentStartDate.isSameOrBefore(momentFiveDays);
      const isEndDateValid = momentEndDate.isSameOrAfter(momentToday);

      return isStartDateValid && isEndDateValid;
    });

  return (
    <Card
      sx={{
        '& .MuiTimelineItem-missingOppositeContent:before': {
          display: 'none'
        },
        'height': '100%',
        'maxHeight': '32.5em',
        'overflowY': 'auto'
      }}>
      <CardHeader title='Upcoming/Current Leaves' />
      <CardContent>
        <Timeline>
          {leavesToDisplay.map((item, index) => (
            <LeaveEntry
              key={item._id}
              item={item}
              isLast={index === leavesToDisplay.length - 1}
            />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
}
