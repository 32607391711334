import moment from 'moment';

import * as XLSX from 'xlsx/xlsx.mjs';

import { formatHours } from './formatNumber';

export const exportToExcel = (rowsToExcel, headers, usersByUserId) => {
  let hoursTotal = 0;

  const sheetRows = rowsToExcel.map((row, index) => {
    hoursTotal += row.hours;
    const nameOfBillAsORMainUser = row.assignment.billAs
      ? `${usersByUserId[row.assignment.billAs].firstName} ${
          usersByUserId[row.assignment.billAs].lastName
        }`
      : `${usersByUserId[row.assignment.userId].firstName} ${
          usersByUserId[row.assignment.userId].lastName
        }`;
    return [
      index + 1,
      moment(row.entryDate).format('DD MMM YYYY'),
      row.assignment.projectName,
      `${nameOfBillAsORMainUser}`,
      row.notes,
      formatHours(row.hours),
      ''
    ];
  });

  sheetRows.push(['TOTAL', '', '', '', '', formatHours(hoursTotal), '']);
  const workSheeet = XLSX.utils.aoa_to_sheet(headers);
  XLSX.utils.sheet_add_aoa(workSheeet, sheetRows, {
    skipHeader: true,
    origin: -1
  });
  workSheeet['!merges'] = [
    { s: { r: sheetRows.length, c: 0 }, e: { r: sheetRows.length, c: 4 } }
  ];
  workSheeet['!cols'] = [
    { width: 8 },
    { width: 14 },
    { width: 16 },
    { width: 16 },
    { width: 60 },
    { width: 10 },
    { width: 30 }
  ];

  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheeet, 'Timesheet');
  // Binary String
  XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
  // Download
  XLSX.writeFile(workBook, 'Timesheet.xlsx');
};
