import _ from 'lodash';

import { Card, Tooltip, Typography } from '@mui/material';

import { alpha, styled } from '@mui/material/styles';

import { Icon } from '@iconify/react';
import fileDoneOutlined from '@iconify/icons-ant-design/file-done-outlined';

import { fShortenNumber } from '../../../utils/formatNumber';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.warning.dark,
    0
  )} 0%, ${alpha(theme.palette.warning.dark, 0.24)} 100%)`
}));

// ----------------------------------------------------------------------

export default function AppActiveProjects({ projects }) {
  const projectsByType = _.groupBy(
    projects.filter((prj) => prj.status === 'Ongoing'),
    'type'
  );

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={fileDoneOutlined} width={36} height={36} />
      </IconWrapperStyle>
      <Typography variant='h3'>
        {fShortenNumber(
          projects
            ? projects.filter((prj) => prj.status === 'Ongoing').length
            : 0
        )}
      </Typography>
      <Tooltip
        title={
          <h3>
            {projectsByType.Client.length} Client&nbsp;|&nbsp;
            {projectsByType.Internal.length} Internal
          </h3>
        }>
        <Typography variant='subtitle2' sx={{ opacity: 0.72 }}>
          Active Projects
        </Typography>
      </Tooltip>
    </RootStyle>
  );
}
