import { useState } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { sample } from 'lodash';

import {
  Avatar,
  Button,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Stack,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';
import {
  amber,
  blue,
  deepOrange,
  deepPurple,
  green,
  pink
} from '@mui/material/colors';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import EmployeeAssignments from './EmployeeAssignments';

const AVATAR_BG_COLORS = [
  amber[400],
  blue[500],
  deepOrange[500],
  deepPurple[500],
  green.A400,
  pink[600]
];

// Get the server URL from environment variable
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

// Component for the employee accordion
export default function EmployeeListCard({
  row,
  setSelectedUser,
  employeeList,
  projectList
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [expandedPanel, setExpandedPanel] = useState();

  const [userProjects, setUserProjects] = useState([]);

  const fetchUserProjects = async (userId) => {
    let prjResponse = await fetch(`${SERVER_URL}/api/users/${userId}/projects`);
    prjResponse = await prjResponse.json();

    if (prjResponse.success) {
      setUserProjects(
        prjResponse.data.map((assgn) => {
          assgn.startDate = moment(assgn.startDate).format('yyyy-MM-DD');
          return assgn;
        })
      );
    } else {
      enqueueSnackbar(prjResponse.data, { variant: 'error' });
    }
  };

  const handleChangeAccordion = (panelId) => async (event, newExpanded) => {
    setExpandedPanel(newExpanded ? panelId : 'null');

    if (newExpanded === false) {
      return;
    }

    await fetchUserProjects(panelId);
  };

  const deactivateUser = async (user) => {
    const { _id } = user;
    let updateResponse = await fetch(`${SERVER_URL}/api/users/${_id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        _id,
        isActive: false,
        dateOfLeaving: moment().format()
      }),
      headers: { 'Content-Type': 'application/json' }
    });

    updateResponse = await updateResponse.json();

    if (updateResponse.success) {
      user.isActive = false;
      await fetchUserProjects(_id);
      enqueueSnackbar('Employee marked as inactive', {
        variant: 'success'
      });
    } else {
      enqueueSnackbar(updateResponse.message, { variant: 'error' });
    }
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    'border': `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    'width': '100%',
    'backgroundColor':
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    'flexDirection': 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1)
    }
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
  }));

  const {
    _id,
    empId,
    firstName,
    lastName,
    designation,
    photoUrl,
    isActive,
    dateOfLeaving
  } = row;

  return (
    <Accordion
      key={_id}
      expanded={expandedPanel === _id}
      onChange={handleChangeAccordion(_id)}>
      <AccordionSummary aria-controls='panel1d-content' id={_id}>
        <Stack
          direction='row'
          spacing={1}
          justifyContent='space-between'
          flexGrow={1}>
          <Stack direction='row' spacing={1}>
            <Avatar
              src={photoUrl || 'noimage'}
              sx={{
                height: 50,
                width: 50,
                bgcolor: sample(AVATAR_BG_COLORS)
              }}>
              {firstName.charAt(0) + lastName.charAt(0)}
            </Avatar>

            <Stack direction='column' spacing={0.5}>
              <Typography
                variant='h6'
                sx={{ color: isActive ? '#000' : '#aaa' }}>
                {`${firstName} ${lastName} (${empId})`}
              </Typography>
              <Typography variant='caption'>{designation}</Typography>
            </Stack>
          </Stack>
          {isActive && (
            <Stack spacing={1} direction='row' sx={{ py: 1 }}>
              <Button
                variant='outlined'
                onClick={(e) => {
                  setSelectedUser(row);
                  e.stopPropagation();
                }}>
                Edit Details
              </Button>
              <Button
                variant='outlined'
                color='error'
                onClick={async (e) => {
                  e.stopPropagation();
                  await deactivateUser(row);
                }}>
                Mark Inactive
              </Button>
            </Stack>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction='column' spacing={0.5}>
          {isActive ? (
            <EmployeeAssignments
              projects={userProjects}
              user={row}
              employeeList={employeeList}
              projectList={projectList}
              fetchUserProjects={fetchUserProjects}
            />
          ) : (
            <Typography variant='subtitle1'>
              Employee has left the organization on{' '}
              {moment(dateOfLeaving).format('DD-MM-YYYY')}
            </Typography>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
