import { useState } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import {
  Typography,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Stack,
  Tooltip
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Save from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';

import * as XLSX from 'xlsx/xlsx.mjs';

const TS_COLUMNS = [
  {
    label: 'Date',
    name: 'Date',
    transform: (val) =>
      moment(val, [
        'D MMM YYYY',
        'DD MMM YYYY',
        'DD MM YYYY',
        'DD MM YY',
        'MM DD YYYY'
      ]).format('YYYY-MM-DDTHH:mm:ssZ')
  },
  { label: 'Project', name: 'Project' },
  { label: 'Resource', name: 'Resource' },
  { label: 'Task Details', name: 'Task Details' },
  { label: 'Hours', name: 'Hours' }
];

const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function UploadTimesheets() {
  const { enqueueSnackbar } = useSnackbar();

  const [timesheetData, setTimesheetData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [fileName, setFileName] = useState();

  const readFileContent = (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsBinaryString(file);
    });
  };

  const headers = [
    {
      'Sr. No.': '',
      'Date': '',
      'Project': '',
      'Resource': '',
      'Task Details': '',
      'Hours': ''
    }
  ];

  const downloadSampleFile = (headers) => {
    const fileName = 'Sample File.xlsx';

    const ws = XLSX.utils.json_to_sheet(headers);

    ws['!cols'] = [
      { width: 8 },
      { width: 20 },
      { width: 16 },
      { width: 16 },
      { width: 30 },
      { width: 20 },
      { width: 20 }
    ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sample File');

    XLSX.writeFile(wb, fileName);
  };

  const handleFileUpload = async (evt) => {
    try {
      if (!evt.target.files || !evt.target.files.length === 0) return;

      const file = evt.target.files[0];
      setFileName(file.name);
      const fileRawData = await readFileContent(file);
      const workbook = XLSX.read(fileRawData, { type: 'binary' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const fileData = XLSX.utils.sheet_to_json(sheet, { raw: false });

      const tableData = fileData
        .filter((row) => !!row.Date)
        .map((row) => {
          const rowData = {};

          TS_COLUMNS.forEach((col) => {
            // If there is any transformation function then pass the data through it
            if (col.transform) {
              row[col.label] = col.transform(row[col.label]);
            }

            rowData[col.label] = row[col.name];
          });

          return rowData;
        });

      setTimesheetData(tableData);
      enqueueSnackbar('File Upload Sucess', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  };

  const handleUploadTimesheet = async () => {
    setIsLoading(true);
    const endpoint = `${SERVER_URL}/api/timeEntries`;

    let response = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(timesheetData),
      headers: { 'Content-Type': 'application/json' }
    });
    response = await response.json();

    if (response.success) {
      setTimesheetData(response.data);
      enqueueSnackbar('Timesheet Imported Successfully', {
        variant: 'success'
      });
    } else {
      enqueueSnackbar(response.data, { variant: 'error' });
    }

    setIsLoading(false);
  };

  function importStatus(val) {
    return {
      missing_pa: (
        <Tooltip title='Missing Project Assignment'>
          <PersonSearchIcon color='warning' />
        </Tooltip>
      ),
      success: (
        <Tooltip title='Successfully Imported'>
          <CheckCircleIcon color='success' />
        </Tooltip>
      ),
      fail: (
        <Tooltip title='Failed to Import'>
          <ErrorIcon color='error' />
        </Tooltip>
      )
    }[val];
  }

  return (
    <Stack direction='column'>
      {timesheetData.length === 0 && (
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={3}>
          <Typography display='inline-block' sx={{ ml: 2 }}>
            {fileName}
          </Typography>
          <Button
            variant='contained'
            component='label'
            endIcon={<UploadFileIcon />}>
            Upload File
            <input
              type='file'
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              hidden
              onChange={handleFileUpload}
            />
          </Button>
          <Typography display='inline-block'>OR</Typography>
          <Button
            variant='contained'
            component='label'
            color='secondary'
            endIcon={<DownloadIcon />}>
            Download Sample File
            <input
              hidden
              onClick={() => {
                downloadSampleFile(headers);
              }}
            />
          </Button>
          <p>
            Please format "Date" cell as "D MMM YYYY" to ensure successful
            upload of timesheet.
          </p>
        </Stack>
      )}
      {timesheetData.length !== 0 && (
        <Stack direction='column'>
          <TableContainer component={Paper} sx={{ height: '400px' }}>
            <Table sx={{ height: 'max-content' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '15%' }}>Date</TableCell>
                  <TableCell sx={{ width: '15%' }}>Project</TableCell>
                  <TableCell sx={{ width: '15%' }}>Resource</TableCell>
                  <TableCell sx={{ width: '35%' }}>Task Details</TableCell>
                  <TableCell sx={{ width: '15%' }}>Hours</TableCell>
                  <TableCell sx={{ width: '5%' }}>Status</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {timesheetData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {moment(row.Date).format('DD MMM YYYY')}
                    </TableCell>
                    <TableCell>{row.Project}</TableCell>
                    <TableCell>{row.Resource}</TableCell>
                    <TableCell>{row['Task Details']}</TableCell>
                    <TableCell>{row.Hours}</TableCell>
                    <TableCell>{importStatus(row.status)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {!isLoading ? (
            <Stack
              direction='row'
              sx={{ mt: 2 }}
              spacing={1}
              justifyContent='flex-end'>
              <Button
                variant='contained'
                onClick={() => {
                  setTimesheetData([]);
                  setFileName();
                }}>
                Reset File
              </Button>

              <Button variant='contained' onClick={handleUploadTimesheet}>
                Upload Timesheet
              </Button>
            </Stack>
          ) : (
            <Stack
              direction='row'
              sx={{ mt: 2 }}
              spacing={1}
              justifyContent='flex-end'>
              <LoadingButton
                loading
                variant='outlined'
                loadingPosition='start'
                startIcon={<Save />}>
                Uploading Timesheet
              </LoadingButton>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
}
