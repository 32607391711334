import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useRef, useState, useContext } from 'react';

import {
  Button,
  Box,
  Divider,
  Typography,
  Avatar,
  IconButton,
  Grid,
  Stack
} from '@mui/material';

import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';

import { alpha } from '@mui/material/styles';
import MenuPopover from '../../../utils/views/MenuPopover';
import ChangePasswordDialog from './ChangePasswordDialog';

import UserContext from '../../../contexts/UserContext';

export default function AccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const { user, setUser } = useContext(UserContext);

  const [openPwdDialod, setOpenDialog] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenPwdDialog = () => {
    setOpenDialog(true);
  };

  const handleClosePwdDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Stack direction='column'>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}>
        <Avatar src={user.photoUrl} alt='photoURL' />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant='subtitle1' noWrap>
            {`${user.firstName} ${user.lastName}`}
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {user.designation}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Grid
          container
          direction='column'
          justifyContent='space-between'
          alignItems='center'
          sx={{ p: 2 }}>
          <Button
            fullWidth
            color='inherit'
            variant='outlined'
            sx={{ mb: 1 }}
            startIcon={<LockIcon />}
            onClick={handleOpenPwdDialog}>
            Change Password
          </Button>
          <Button
            fullWidth
            color='inherit'
            variant='outlined'
            sx={{ mb: 1 }}
            startIcon={<EditIcon />}>
            Edit Profile
          </Button>
          <Button
            fullWidth
            color='inherit'
            variant='outlined'
            onClick={() => {
              setUser(null);
              Cookies.remove('auth-cookie');
              navigate('/', { replace: true });
            }}
            startIcon={<LogoutIcon />}>
            Logout
          </Button>
        </Grid>
      </MenuPopover>
      <ChangePasswordDialog
        open={openPwdDialod}
        user={user}
        onClose={handleClosePwdDialog}
      />
    </Stack>
  );
}
