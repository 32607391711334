import { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import {
  Container,
  Typography,
  Grid,
  Stack,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import Page from '../../utils/views/Page';

import ManageProject from './components/ManageProject';
import ProjectCard from './components/ProjectCard';

import UserContext from '../../contexts/UserContext';

// Get the server URL from environment variable
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function Projects() {
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useContext(UserContext);

  const [projects, setProjects] = useState([]);

  const [userList, setUserList] = useState([]);

  const [showAddProject, setShowAddProject] = useState(false);

  const [selectedProjectStatus, setProjectStatus] = useState('Ongoing');

  const [selectedProjectType, setProjectType] = useState('Client');

  const [filterProjects, setFilterProjects] = useState([]);

  useEffect(async () => {
    await refreshProjects();

    await fetchUsers();
  }, []);

  const fetchUsers = async () => {
    let users = await fetch(`${SERVER_URL}/api/users`);
    users = await users.json();

    if (users.success) {
      setUserList(users.data);
    }
  };

  const refreshProjects = async () => {
    let projects;
    if (
      user.permissions &&
      user.permissions.pageAccess &&
      user.permissions.pageAccess.projects &&
      user.permissions.recordAccess.projects.readAll
    ) {
      projects = await fetch(`${SERVER_URL}/api/projects`);
    } else if (user.permissions.recordAccess.projects.read) {
      projects = await fetch(`${SERVER_URL}/api/projects?userId=${user._id}`);
    }

    projects = await projects.json();

    const formatDate = (date) => moment(date).format('yyyy-MM-DD');
    if (projects.success) {
      const formattedProjects = projects.data.map((prj) => ({
        ...prj,
        startDate: formatDate(prj.startDate),
        closeDate: formatDate(prj.closeDate || moment().add(90, 'days'))
      }));

      const filteredProjects = filterProjectStatusAndType(formattedProjects, selectedProjectStatus, selectedProjectType);

      setProjects(formattedProjects);
      setFilterProjects(filteredProjects);
    } else {
      enqueueSnackbar(projects.data, { variant: 'error' });
    }
  };

  const openAddProject = () => {
    setShowAddProject(true);
  };

  const filterProjectStatusAndType = (rows, valueOfStatus, valueOfType) => {
    const finalRows = [];

    setFilterProjects([]);
    rows.filter((val) => {
      let returnIt = false;
      if (
        (valueOfStatus === 0 || valueOfStatus === val.status) &&
        (valueOfType === 0 || valueOfType === val.type)
      ) {
        returnIt = true;
        finalRows.push(val);
      }
      return returnIt;
    });

    setFilterProjects(finalRows);
    return finalRows;
  }

  function EnhancedTable() {
    const handleChangeProjectStatus = (event) => {
      setProjectStatus(event.target.value);
      filterProjectStatusAndType(
        projects,
        event.target.value,
        selectedProjectType
      );
    };

    const handleChangeProjectType = (event) => {
      setProjectType(event.target.value);
      filterProjectStatusAndType(
        projects,
        selectedProjectStatus,
        event.target.value
      );
    };

    return (
      <Stack direction='row'>
        <FormControl sx={{ m: 1, minWidth: 150 }} size='small'>
          <InputLabel id='demo-simple-select-autowidth-label'>
            Status
          </InputLabel>
          <Select
            labelId='demo-simple-select-standard-label'
            id='demo-simple-select-standard'
            value={selectedProjectStatus}
            onChange={handleChangeProjectStatus}
            autoWidth
            label='Status'>
            <MenuItem value={0}>None</MenuItem>
            <MenuItem value='On Hold'>On Hold</MenuItem>
            <MenuItem value='Ongoing'>Ongoing</MenuItem>
            <MenuItem value='Completed'>Completed</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 150 }} size='small'>
          <InputLabel id='demo-simple-select-autowidth-label'>Type</InputLabel>
          <Select
            labelId='demo-simple-select-standard-label'
            id='demo-simple-select-standard'
            value={selectedProjectType}
            onChange={handleChangeProjectType}
            autoWidth
            label='Type'>
            <MenuItem value={0}>None</MenuItem>
            <MenuItem value='Client'>Client</MenuItem>
            <MenuItem value='Internal'>Internal</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    );
  }

  return (
    <Page title='Projects'>
      <Container>
        <Typography variant='h4'>Projects</Typography>
        <Grid
          container
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          sx={{ mb: 3 }}>
          {user.permissions &&
            user.permissions.recordAccess &&
            user.permissions.recordAccess.projects.readAll && (
              <Stack direction='row' spacing={2} alignItems='center'>
                <EnhancedTable />
                <Button
                  sx={{ height: '2.5rem' }}
                  variant='outlined'
                  startIcon={<AddIcon />}
                  onClick={openAddProject}>
                  Add Project
                </Button>
              </Stack>
            )}
        </Grid>
        <Grid container spacing={3}>
          {filterProjects.map((project) => (
            <Grid key={project._id} item xs={12} sm={6} md={6}>
              <ProjectCard
                project={project}
                refreshProjects={refreshProjects}
                employeeList={userList}
              />
            </Grid>
          ))}
        </Grid>

        {showAddProject === true ? (
          <ManageProject
            setShowAddProject={setShowAddProject}
            refreshProjects={refreshProjects}
            userList={userList}
          />
        ) : null}
      </Container>
    </Page>
  );
}
