import { useEffect, useState } from 'react';

import moment from 'moment';
import { merge, groupBy, sumBy } from 'lodash';

import { Card, CardHeader, Box } from '@mui/material';

import ReactApexChart from 'react-apexcharts';
import BaseOptionChart from '../../../utils/charts/BaseOptionChart';

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
const monthName = (item) => moment(item.entryDate).format('MMM');

export default function AppHoursBilled({ projects }) {
  const [chartData, setChartData] = useState([]);

  const chartMonths = (() => {
    const returnVal = [];
    const today = new Date();

    for (let i = 5; i >= 0; i -= 1) {
      const dt = new Date(today.getFullYear(), today.getMonth() - i, 1);
      returnVal.push(monthNames[dt.getMonth()]);
    }

    return returnVal;
  })();

  useEffect(() => {
    if (projects) {
      const projectMonthlyHours = {};

      projects.forEach((prj) => {
        const hoursByMonth = {};

        prj.assignments.forEach((assgn) => {
          const timeEntriesByMonth = groupBy(
            assgn.timeEntries.filter(
              (timeEntry) => timeEntry.isBillable && timeEntry.isApproved
            ),
            monthName
          );

          Object.keys(timeEntriesByMonth).forEach((month) => {
            hoursByMonth[month] =
              (hoursByMonth[month] || 0) +
              sumBy(timeEntriesByMonth[month], 'hours');
          });
        });

        const existingHrs = projectMonthlyHours[prj.projectName];
        if (existingHrs) {
          Object.keys(existingHrs).forEach((month) => {
            hoursByMonth[month] = hoursByMonth[month] || 0;
            hoursByMonth[month] += existingHrs[month];
          });
        }

        projectMonthlyHours[prj.projectName] = hoursByMonth;
      });

      const today = moment();
      const thisMonth = today.format('M');
      const lastMonth = today.clone().subtract(1, 'months').format('MMM');
      const lastSecondMonth = today.clone().subtract(2, 'months').format('MMM');

      const projectMonthlyHoursToShow = {};
      Object.keys(projectMonthlyHours).forEach((prjName) => {
        if (
          projectMonthlyHours[prjName][thisMonth] ||
          projectMonthlyHours[prjName][lastMonth] ||
          projectMonthlyHours[prjName][lastSecondMonth]
        ) {
          projectMonthlyHoursToShow[prjName] = projectMonthlyHours[prjName];
        }
      });

      setChartData(
        Object.keys(projectMonthlyHoursToShow).map((project) => {
          const hoursByMonth = projectMonthlyHoursToShow[project];

          const data = chartMonths.map((month) => {
            if (hoursByMonth[month]) return hoursByMonth[month];

            return 0;
          });

          return { name: project, type: 'line', data };
        })
      );
    }
  }, [projects]);

  const chartOptions = merge(BaseOptionChart(), {
    stroke: {
      width: 3
    },
    plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
    fill: {
      type: 'solid'
    },
    labels: chartMonths,
    xaxis: { type: 'text' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} hours`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card sx={{ height: '100%', maxHeight: '32.5em' }}>
      <CardHeader title='Hours Billed' />
      <Box sx={{ p: 3, pb: 1 }} dir='ltr'>
        <ReactApexChart
          type='line'
          series={chartData}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
