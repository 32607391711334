import { useSnackbar } from 'notistack';

import {
  Typography,
  Stack,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Checkbox
} from '@mui/material';

import DualListBox from 'react-dual-listbox';

const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function PermissionManager({ appData }) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '15%' }}>Employee</TableCell>
            <TableCell sx={{ width: '35%' }} align='left'>
              Page Access
            </TableCell>
            <TableCell sx={{ width: '35%' }} align='left'>
              Record Access
            </TableCell>
            <TableCell sx={{ width: '15%' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {appData.users
            .filter((user) => !!user.isActive)
            .map((user) => {
              user.permissions = user.permissions || {};
              user.permissions.pageAccess = user.permissions.pageAccess || {
                admin: false,
                dashboard: false,
                employees: false,
                projects: false,
                timesheets: false,
                leaves: false,
                holidays: false
              };

              user.permissions.recordAccess = user.permissions.recordAccess || {
                employees: { read: false, readAll: false },
                projects: { read: false, readAll: false },
                assignments: { read: false, readAll: false },
                leaves: { read: false, readAll: false },
                timesheets: { read: false, readAll: false },
                holidays: { read: false, readAll: false }
              };

              const { pageAccess } = user.permissions;
              const { recordAccess } = user.permissions;

              const selectedPageAccess = Object.keys(pageAccess).filter(
                (page) => !!pageAccess[page]
              );

              return (
                <TableRow key={user._id}>
                  <TableCell component='th'>{`${user.firstName} ${user.lastName}`}</TableCell>
                  <TableCell align='left'>
                    <DualListBox
                      icons={{
                        moveLeft: '<',
                        moveAllLeft: '<<',
                        moveRight: '>',
                        moveAllRight: '>>'
                      }}
                      options={Object.keys(pageAccess).map((access) => ({
                        label: access,
                        value: access
                      }))}
                      selected={selectedPageAccess}
                      onChange={(selected, selection, controlKey) => {
                        if (controlKey === 'available') {
                          selectedPageAccess.push(selection[0]);
                        } else if (controlKey === 'selected') {
                          selectedPageAccess.splice(
                            selectedPageAccess.indexOf(selection[0]),
                            1
                          );
                        }

                        Object.keys(pageAccess).forEach((access) => {
                          pageAccess[access] =
                            selectedPageAccess.indexOf(access) !== -1;
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell align='left'>
                    <Stack spacing={0}>
                      <Stack
                        direction='row'
                        spacing={6}
                        alignItems='center'
                        justifyContent='space-between'>
                        <Typography sx={{ width: '80%' }}>
                          Object Name
                        </Typography>
                        <Typography>Read Mine</Typography>
                        <Typography>Read All</Typography>
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={6}
                        alignItems='center'
                        justifyContent='space-between'>
                        <Typography sx={{ width: '80%' }}>Employees</Typography>
                        <Checkbox
                          defaultChecked={recordAccess.employees.read}
                          onChange={(evt) =>
                            (recordAccess.employees.read = evt.target.checked)
                          }
                        />
                        <Checkbox
                          defaultChecked={recordAccess.employees.readAll}
                          onChange={(evt) =>
                            (recordAccess.employees.readAll =
                              evt.target.checked)
                          }
                        />
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={6}
                        alignItems='center'
                        justifyContent='space-between'>
                        <Typography sx={{ width: '80%' }}>Projects</Typography>
                        <Checkbox
                          defaultChecked={recordAccess.projects.read}
                          onChange={(evt) =>
                            (recordAccess.projects.read = evt.target.checked)
                          }
                        />
                        <Checkbox
                          defaultChecked={recordAccess.projects.readAll}
                          onChange={(evt) =>
                            (recordAccess.projects.readAll = evt.target.checked)
                          }
                        />
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={6}
                        alignItems='center'
                        justifyContent='space-between'>
                        <Typography sx={{ width: '80%' }}>
                          Assignments
                        </Typography>
                        <Checkbox
                          defaultChecked={recordAccess.assignments.read}
                          onChange={(evt) =>
                            (recordAccess.assignments.read = evt.target.checked)
                          }
                        />
                        <Checkbox
                          defaultChecked={recordAccess.assignments.readAll}
                          onChange={(evt) =>
                            (recordAccess.assignments.readAll =
                              evt.target.checked)
                          }
                        />
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={6}
                        alignItems='center'
                        justifyContent='space-between'>
                        <Typography sx={{ width: '80%' }}>Leaves</Typography>
                        <Checkbox
                          defaultChecked={recordAccess.leaves.read}
                          onChange={(evt) =>
                            (recordAccess.leaves.read = evt.target.checked)
                          }
                        />
                        <Checkbox
                          defaultChecked={recordAccess.leaves.readAll}
                          onChange={(evt) =>
                            (recordAccess.leaves.readAll = evt.target.checked)
                          }
                        />
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={6}
                        alignItems='center'
                        justifyContent='space-between'>
                        <Typography sx={{ width: '80%' }}>
                          Timesheets
                        </Typography>
                        <Checkbox
                          defaultChecked={recordAccess.timesheets.read}
                          onChange={(evt) =>
                            (recordAccess.timesheets.read = evt.target.checked)
                          }
                        />
                        <Checkbox
                          defaultChecked={recordAccess.timesheets.readAll}
                          onChange={(evt) =>
                            (recordAccess.timesheets.readAll =
                              evt.target.checked)
                          }
                        />
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={6}
                        alignItems='center'
                        justifyContent='space-between'>
                        <Typography sx={{ width: '80%' }}>Holidays</Typography>
                        <Checkbox
                          defaultChecked={recordAccess.holidays?.read}
                          onChange={(evt) =>
                            (recordAccess.holidays.read = evt.target.checked)
                          }
                        />
                        <Checkbox
                          defaultChecked={recordAccess.holidays?.readAll}
                          onChange={(evt) =>
                            (recordAccess.holidays.readAll = evt.target.checked)
                          }
                        />
                      </Stack>
                    </Stack>
                  </TableCell>
                  <TableCell align='center'>
                    <Button
                      variant='contained'
                      onClick={async () => {
                        const endpoint = `${SERVER_URL}/api/users/${user._id}`;
                        const method = 'PATCH';
                        let userUpdate = await fetch(endpoint, {
                          method,
                          body: JSON.stringify(user),
                          headers: {
                            'Content-Type': 'application/json'
                          }
                        });
                        userUpdate = await userUpdate.json();

                        if (userUpdate.success) {
                          enqueueSnackbar(
                            'Employee permissions saved successfully',
                            {
                              variant: 'success'
                            }
                          );
                        } else {
                          enqueueSnackbar(userUpdate.data, {
                            variant: 'error'
                          });
                        }
                      }}>
                      Save
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
