import { useState } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';

import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FilledInput,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField
} from '@mui/material';

import { formatUsd, formatHours } from '../../../utils/formatNumber';

// Get the server URL from environment variable
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function EditProject({
  projectDetails,
  setShowAddProject,
  refreshProjects,
  userList
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [showDialog, setShowDialog] = useState(true);

  const ProjectSchema = Yup.object().shape({
    projectName: Yup.string().required('Please enter the project name'),
    clientName: Yup.string().required('Please enter the client name'),
    startDate: Yup.date().required('Please enter the start date'),
    closeDate: Yup.date(),
    priority: Yup.string(),
    type: Yup.string().required('Please choose the project type'),
    status: Yup.string(),
    estimatedHours: Yup.number(),
    manager: Yup.string().required('Manager is mandatory')
  });

  const formik = useFormik({
    initialValues: projectDetails || {
      projectName: '',
      clientName: '',
      priority: 'Normal',
      type: 'Client',
      status: 'Ongoing',
      startDate: moment().format('yyyy-MM-DD'),
      closeDate: moment().add(90, 'days').format('yyyy-MM-DD'),

      totalHours: 0,
      totalBillableHours: 0,
      totalNonBillableHours: 0,
      revenue: 0,
      manager: ''
    },
    validationSchema: ProjectSchema,
    onSubmit: async (values) => {
      values.startDate = moment(values.startDate).format();
      values.closeDate = moment(values.closeDate).format();

      const endpoint = projectDetails
        ? `${SERVER_URL}/api/projects/${projectDetails._id}`
        : `${SERVER_URL}/api/projects/`;

      let prjResponse = await fetch(endpoint, {
        method: projectDetails ? 'PATCH' : 'POST',
        body: JSON.stringify(values),
        headers: { 'Content-Type': 'application/json' }
      });
      prjResponse = await prjResponse.json();

      let newUserResponse;

      if (!projectDetails) {
        // Project manager assignment
        const assValues = {
          userId: values.manager,
          projectId: prjResponse.data._id,
          role: 'Manager',
          isActive: true,
          startDate: values.startDate,
          rate: 0
        };

        newUserResponse = await fetch(`${SERVER_URL}/api/assignments`, {
          method: 'POST',
          body: JSON.stringify(assValues),
          headers: { 'Content-Type': 'application/json' }
        });
        newUserResponse = await newUserResponse.json();
      }

      const isSuccess =
        prjResponse.success && newUserResponse ? newUserResponse.success : true;

      if (isSuccess) {
        await refreshProjects();
        enqueueSnackbar('Project details saved successfully', {
          variant: 'success'
        });
        resetForm();
        setShowDialog(false);
        setShowAddProject(false);
      } else {
        enqueueSnackbar(prjResponse.data, { variant: 'error' });
      }
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm
  } = formik;

  return (
    <div>
      <Dialog
        open={showDialog}
        onClose={(e, reason) => {
          if (reason === 'backdropClick') {
            e.stopPropagation();
            return;
          }
          resetForm();
          setShowDialog(false);
          setShowAddProject(false);
        }}
        fullWidth
        maxWidth='lg'>
        <DialogTitle>Manage Project</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Chip label='Project Details' sx={{ mb: 2 }} />
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='text'
                    label='Project Name'
                    autoComplete='projectName'
                    {...getFieldProps('projectName')}
                    error={Boolean(touched.projectName && errors.projectName)}
                    helperText={touched.projectName && errors.projectName}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='text'
                    label='Client Name'
                    autoComplete='clientName'
                    {...getFieldProps('clientName')}
                    error={Boolean(touched.clientName && errors.clientName)}
                    helperText={touched.clientName && errors.clientName}
                  />
                </Grid>

                {!projectDetails && (
                  <Grid container item xs={6}>
                    <TextField
                      size='small'
                      fullWidth
                      select
                      type='select'
                      label='Type'
                      {...getFieldProps('type')}
                      error={Boolean(touched.type && errors.type)}
                      helperText={touched.type && errors.type}>
                      {['Internal', 'Client'].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}

                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    select
                    type='select'
                    label='Status'
                    {...getFieldProps('status')}
                    error={Boolean(touched.status && errors.status)}
                    helperText={touched.status && errors.status}>
                    {['On Hold', 'Ongoing', 'Completed'].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    select
                    type='select'
                    label='Priority'
                    {...getFieldProps('priority')}
                    error={Boolean(touched.priority && errors.priority)}
                    helperText={touched.priority && errors.priority}>
                    {['Normal', 'At Risk'].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid container item xs={6}>
                  {userList && (
                    <TextField
                      size='small'
                      fullWidth
                      select
                      type='select'
                      label='Manager'
                      {...getFieldProps('manager')}
                      error={Boolean(touched.manager && errors.manager)}
                      helperText={touched.manager && errors.manager}>
                      {userList
                        .filter((user) => user.isActive)
                        .map((user) => (
                          <MenuItem key={user._id} value={user._id}>
                            {`${user.firstName} ${user.lastName}`}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                </Grid>
              </Grid>

              <Chip label='Hours &amp; Estimates' sx={{ mb: 2, mt: 2 }} />

              <Grid container rowSpacing={2} columnSpacing={2}>
                {projectDetails && (
                  <Grid container item xs={6}>
                    <FormControl disabled variant='filled' fullWidth>
                      <InputLabel htmlFor='revenue'>
                        Revenue Generated
                      </InputLabel>
                      <FilledInput
                        id='revenue'
                        value={formatUsd(values.revenue)}
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                )}

                {projectDetails && <Grid container item xs={6} />}

                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='date'
                    label='Start Date'
                    {...getFieldProps('startDate')}
                    error={Boolean(touched.startDate && errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                  />
                </Grid>

                {projectDetails && (
                  <Grid container item xs={6}>
                    <TextField
                      size='small'
                      fullWidth
                      type='date'
                      label='Close Date'
                      {...getFieldProps('closeDate')}
                      error={Boolean(touched.closeDate && errors.closeDate)}
                      helperText={touched.closeDate && errors.closeDate}
                    />
                  </Grid>
                )}

                {projectDetails && (
                  <Grid container item xs={6}>
                    <FormControl disabled variant='filled' fullWidth>
                      <InputLabel htmlFor='hrsSpent'>
                        Total Hours Spent
                      </InputLabel>
                      <FilledInput
                        id='hrsSpent'
                        value={formatHours(values.totalHours)}
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                )}

                {projectDetails && (
                  <Grid container item xs={6}>
                    <FormControl disabled variant='filled' fullWidth>
                      <InputLabel htmlFor='billableHrs'>
                        Total Billable Hours
                      </InputLabel>
                      <FilledInput
                        id='billableHrs'
                        value={formatHours(values.totalBillablehours)}
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                )}

                {projectDetails && (
                  <Grid container item xs={6}>
                    <FormControl disabled variant='filled' fullWidth>
                      <InputLabel htmlFor='nonBillableHrs'>
                        Total Non Billable Hours
                      </InputLabel>
                      <FilledInput
                        id='nonBillableHrs'
                        value={formatHours(values.totalNonBillableHours)}
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  resetForm();
                  setShowAddProject(false);
                  setShowDialog(false);
                }}>
                Cancel
              </Button>
              <Button type='submit' disabled={isSubmitting}>
                Save
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
