import { useContext, useState } from 'react';

import { Box, Container, Typography, Stack, Tabs, Tab } from '@mui/material';

import Page from '../../utils/views/Page';

import PermissionManager from './components/PermissionManager';
import ResetPasswords from './components/ResetPasswords';
import UploadTimesheets from './components/UploadTimesheets';
import RecalculateTimeEntries from './components/RecalculateTimeEntries';

import AppDataContext from '../../contexts/AppContext';

import './css/styles.css';

const TABS = [
  { value: 0, label: 'User Permissions' },
  { value: 1, label: 'Reset Passwords' },
  { value: 2, label: 'Upload Timesheets' },
  { value: 3, label: 'Recalculate Time Entries' }
];

export default function AdminCenter() {
  const { appData } = useContext(AppDataContext);

  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (evt, newValue) => {
    setCurrentTab(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return appData ? (
    <Page title='Admin Center'>
      <Container maxWidth='xl'>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={3}>
          <Typography variant='h4' gutterBottom>
            Admin Center
          </Typography>
        </Stack>

        <Tabs value={currentTab} onChange={handleTabChange}>
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              id={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>

        <TabPanel value={currentTab} index={0}>
          <PermissionManager appData={appData} />
        </TabPanel>

        <TabPanel value={currentTab} index={1}>
          <ResetPasswords appData={appData} />
        </TabPanel>

        <TabPanel value={currentTab} index={2}>
          <UploadTimesheets />
        </TabPanel>

        <TabPanel value={currentTab} index={3}>
          <RecalculateTimeEntries />
        </TabPanel>
      </Container>
    </Page>
  ) : null;
}
