import React, { useContext, useState, useEffect } from 'react';

import moment from 'moment';
import { keyBy } from 'lodash';

import {
  FormGroup,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  MenuItem,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Stack,
  TablePagination,
  TableSortLabel,
  FormControl
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import TableRowsIcon from '@mui/icons-material/TableRows';

import { visuallyHidden } from '@mui/utils';
import { formatHours } from '../../../utils/formatNumber';

import UserContext from '../../../contexts/UserContext';

const MONTHS = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 }
];

const headCells = [
  {
    id: 'entryDate',
    sortable: true,
    label: 'Date'
  },

  {
    id: 'projectId',
    sortable: true,
    label: 'Project'
  },
  {
    id: 'hours',
    label: 'Hours'
  },
  {
    id: 'isBillable',
    label: 'Billable?'
  },
  {
    id: 'notes',
    label: 'Notes'
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            sortDirection={orderBy === headCell.id ? order : false}>
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell align='center'>Status</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function EmployeeTimesheets({ timeEntries, assignments }) {
  const { user } = useContext(UserContext);

  let rowsToExcel = [];
  let finalRows = [];

  const [projectsById, setProjectsById] = useState({});

  const [rowsToDisplay, setRowsToDisplay] = useState([]);

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState('entryDate');

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (!timeEntries || !timeEntries.length) return;

    setRowsToDisplay(timeEntries);
  }, [timeEntries]);

  useEffect(() => {
    if (!assignments || !assignments.length) return;

    setProjectsById(keyBy(assignments, '_id'));
  }, [assignments]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function filterTimesheets(rows) {
    rowsToExcel = [];
    finalRows = [];

    return rows.filter((val) => {
      let returnIt = false;

      if (
        selectedMonth === 0 ||
        (moment(val.entryDate).month() === selectedMonth - 1 &&
          val.userId === user._id) // Filter by the currently logged-in user
      ) {
        returnIt = true;
        finalRows.push(val);
        rowsToExcel.push(val);
      }
      return returnIt;
    });
  }

  return (
    <Card>
      <CardHeader title='My Timesheets' />
      {rowsToDisplay.length === 0 ? (
        <Stack
          direction='column'
          justifyContent='space-around'
          alignItems='center'
          spacing={1}
          sx={{ mt: 4, mb: 2 }}>
          <Typography color='error'>
            <TableRowsIcon fontSize='large' />
          </Typography>
          <Typography color='error'>
            No timesheets found for the month
          </Typography>
        </Stack>
      ) : (
        <CardContent>
          <Stack direction='column' spacing={4}>
            <Stack direction='row' spacing={1} justifyContent='flex-end'>
              <FormGroup row>
                <FormControl sx={{ mr: 1, width: '10rem' }}>
                  <TextField
                    select
                    label='Select Month'
                    value={selectedMonth}
                    onChange={(e) => {
                      setSelectedMonth(e.target.value);
                    }}
                    size='small'>
                    <MenuItem value={0}>None</MenuItem>
                    {MONTHS.map((month) => (
                      <MenuItem key={month.value} value={month.value}>
                        {month.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </FormGroup>
            </Stack>

            <Box>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: '100%' }}
                  size='small'
                  aria-label='a dense table'>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={filterTimesheets(rowsToDisplay).length}
                  />

                  <TableBody>
                    {stableSort(
                      filterTimesheets(rowsToDisplay),
                      getComparator(order, orderBy)
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow
                          key={row._id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 }
                          }}>
                          <TableCell align='center' width='10%'>
                            {moment(row.entryDate).format('DD MMM YY')}
                          </TableCell>

                          <TableCell align='center' width='10%'>
                            {projectsById[row.assignmentId].projectName}
                          </TableCell>
                          <TableCell align='center' width='10%'>
                            {formatHours(row.hours)}
                          </TableCell>
                          <TableCell align='center' width='10%'>
                            {row.isBillable ? (
                              <CheckIcon color='success' />
                            ) : (
                              <CloseIcon color='error' />
                            )}
                          </TableCell>
                          <TableCell align='center' width='25%'>
                            {row.notes}
                          </TableCell>
                          <TableCell align='center' width='40%'>
                            <Stack
                              direction='column'
                              spacing={2}
                              justifyContent='center'>
                              <Stack direction='row' spacing={1}>
                                {row.isApproved && (
                                  <>
                                    <ThumbUpOffAltIcon
                                      color='success'
                                      sx={{ marginRight: '0.15em' }}
                                    />
                                    Approved
                                  </>
                                )}
                                {row.isRejected && (
                                  <>
                                    <ThumbDownAltOutlinedIcon
                                      color='error'
                                      sx={{ marginRight: '0.15em' }}
                                    />
                                    Rejected: {row.rejectionReason}
                                  </>
                                )}
                                {!row.isApproved && !row.isRejected && (
                                  <>
                                    <PendingActionsIcon
                                      color='info'
                                      sx={{ marginRight: '0.15em' }}
                                    />
                                    Pending
                                  </>
                                )}
                              </Stack>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component='div'
                count={finalRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Stack>
        </CardContent>
      )}
    </Card>
  );
}
