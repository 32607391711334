import React, { useState } from 'react';

import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { sample } from 'lodash';
import moment from 'moment';

import { useFormik, Form, FormikProvider } from 'formik';

import {
  Button,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Avatar
} from '@mui/material';

import {
  amber,
  blue,
  deepOrange,
  deepPurple,
  green,
  pink
} from '@mui/material/colors';

import Delete from '@mui/icons-material/Delete';

const AVATAR_BG_COLORS = [
  amber[400],
  blue[500],
  deepOrange[500],
  deepPurple[500],
  green.A400,
  pink[600]
];

// Get the server URL from environment variable
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function ManageEmployeeDetails({
  employeeDetails,
  setShowNewEmployee,
  setSelectedUser,
  forceRefresh
}) {
  const { enqueueSnackbar } = useSnackbar();

  const UserSchema = Yup.object().shape({
    aadharNumber: Yup.string()
      .min(12, 'Aadhaar number must be 12 digits')
      .max(12, 'Aadhaar number must be 12 digits')
      .required('Please enter Aadhar number'),
    currentAddress: Yup.string().required('Please enter current address'),
    dob: Yup.date()
      .required('Please enter the date of birth')
      .test(
        'dob',
        'Age cannot be less than 18 years',
        (value) => moment().diff(moment(value), 'years') >= 18
      ),
    dateOfJoining: Yup.date().required('Please enter the date of joining'),
    dateOfLeaving: Yup.date(),
    departments: Yup.array(Yup.string()).required(
      'Please enter the department'
    ),
    designation: Yup.string().required('Please enter the designation'),
    emergencyContactName: Yup.string(),
    emergencyContactNumber: Yup.string(),
    empType: Yup.string().required('Please select the type of employee'),
    firstName: Yup.string().required('Please enter the first name'),
    lastName: Yup.string().required('Please enter the last name'),
    organizationEmail: Yup.string()
      .email('Organization email must be a valid email address')
      .required('Please enter the organization email'),
    pan: Yup.string()
      .min(10, 'PAN must be a 10 character value')
      .max(10, 'PAN must be a 10 character value')
      .required('Please enter PAN'),
    password: Yup.string().default('smijay@123'),
    permanentAddress: Yup.string().required('Please enter permanent address'),
    personalEmail: Yup.string()
      .email('Personal email must be a valid email address')
      .required('Please enter the personal email'),
    phone: Yup.string()
      .min(10, 'Phone number must be at least 10 digits long')
      .max(10, 'Phone number cannot be more than 10 digits')
      .required('Please enter the phone number'),
    totalExperience: Yup.number(),
    uan: Yup.string()
      .min(10, 'UAN must be a 10 digit value')
      .max(10, 'UAN must be a 10 digit value')
  });

  const formik = useFormik({
    initialValues: employeeDetails || {
      firstName: '',
      lastName: '',
      phone: '',
      personalEmail: '',
      currentAddress: '',
      permanentAddress: '',
      dob: '2000-01-01',
      emergencyContactName: '',
      emergencyContactNumber: '',
      aadharNumber: '',
      pan: '',
      uan: '',
      dateOfJoining: moment().format('yyyy-MM-DD'),
      designation: '',
      empType: '',
      organizationEmail: '',
      departments: [],
      totalExperience: 0,
      photoUrl: ''
    },
    validationSchema: UserSchema,
    onSubmit: async (values) => {
      values.dob = moment(values.dob).format();
      values.dateOfJoining = moment(values.dateOfJoining).format();

      const endpoint = employeeDetails
        ? `${SERVER_URL}/api/users/${employeeDetails._id}`
        : `${SERVER_URL}/api/users`;
      const method = employeeDetails ? 'PATCH' : 'POST';

      let newUserResponse = await fetch(endpoint, {
        method,
        body: JSON.stringify(values),
        headers: { 'Content-Type': 'application/json' }
      });
      newUserResponse = await newUserResponse.json();

      if (newUserResponse.success) {
        await forceRefresh();
        resetForm();
        setSelectedUser(undefined);
        setShowNewEmployee(false);
        setShowDialog(false);
        enqueueSnackbar('Employee details saved successfully', {
          variant: 'success'
        });
      } else {
        enqueueSnackbar(newUserResponse.data, { variant: 'error' });
      }
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue
  } = formik;

  const [showDialog, setShowDialog] = useState(true);

  const encodeImageFileAsURL = async (file) =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });

  return (
    <div>
      <Dialog
        open={showDialog}
        fullWidth
        maxWidth='lg'
        onClose={(e, reason) => {
          if (reason === 'backdropClick') {
            e.stopPropagation();
            return;
          }
          resetForm();
          setSelectedUser(undefined);
          setShowNewEmployee(false);
          setShowDialog(false);
        }}>
        <DialogTitle>Manage Employee</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Chip label='Personal Details' sx={{ mb: 2 }} />
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='text'
                    label='First Name'
                    autoComplete='firstName'
                    {...getFieldProps('firstName')}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                </Grid>
                <Grid container item xs={6} justifyContent='center'>
                  <Button component='label'>
                    <input
                      type='file'
                      accept='image/*'
                      style={{ display: 'none' }}
                      onChange={async (e) => {
                        const [file] = e.currentTarget.files;

                        if (file.size / 1024 / 1024 > 1) {
                          enqueueSnackbar('Max file size is 1 MB', {
                            variant: 'warning'
                          });
                          return;
                        }

                        setFieldValue(
                          'photoUrl',
                          await encodeImageFileAsURL(file)
                        );
                      }}
                    />
                    <Tooltip title='Upload/Change Photo'>
                      <Avatar
                        src={values.photoUrl || 'noimage'}
                        sx={{
                          height: 128,
                          width: 128,
                          bgcolor: sample(AVATAR_BG_COLORS),
                          fontSize: '4rem'
                        }}>
                        {values.firstName.charAt(0) + values.lastName.charAt(0)}
                      </Avatar>
                    </Tooltip>
                    <Tooltip title='Remove Photo'>
                      <IconButton
                        onClick={() => setFieldValue('photoUrl', null)}
                        sx={{
                          alignSelf: 'flex-end',
                          px: 0
                        }}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Button>
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='text'
                    label='Last Name'
                    autoComplete='lastName'
                    {...getFieldProps('lastName')}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='text'
                    label='Phone'
                    {...getFieldProps('phone')}
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='text'
                    label='Personal Email'
                    {...getFieldProps('personalEmail')}
                    error={Boolean(
                      touched.personalEmail && errors.personalEmail
                    )}
                    helperText={touched.personalEmail && errors.personalEmail}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='textarea'
                    label='Current Address'
                    multiline
                    {...getFieldProps('currentAddress')}
                    error={Boolean(
                      touched.currentAddress && errors.currentAddress
                    )}
                    helperText={touched.currentAddress && errors.currentAddress}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='textarea'
                    label='Permanent Address'
                    multiline
                    {...getFieldProps('permanentAddress')}
                    error={Boolean(
                      touched.permanentAddress && errors.permanentAddress
                    )}
                    helperText={
                      touched.permanentAddress && errors.permanentAddress
                    }
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='date'
                    label='Date of Birth'
                    {...getFieldProps('dob')}
                    error={Boolean(touched.dob && errors.dob)}
                    helperText={touched.dob && errors.dob}
                  />
                </Grid>
                <Grid container item xs={6} />
              </Grid>
              <Chip label='Organization Details' sx={{ mt: 3, mb: 2 }} />
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='text'
                    label='Organization Email'
                    {...getFieldProps('organizationEmail')}
                    error={Boolean(
                      touched.organizationEmail && errors.organizationEmail
                    )}
                    helperText={
                      touched.organizationEmail && errors.organizationEmail
                    }
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='text'
                    label='Designation'
                    {...getFieldProps('designation')}
                    error={Boolean(touched.designation && errors.designation)}
                    helperText={touched.designation && errors.designation}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id='departmentId'>Departments</InputLabel>
                    <Select
                      labelId='departmentId'
                      size='small'
                      fullWidth
                      multiple
                      label='Departments'
                      {...getFieldProps('departments')}
                      onChange={formik.handleChange}
                      input={<OutlinedInput label='Departments' />}
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5
                          }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}>
                      {['Management', 'Delivery', 'Marketing', 'HR'].map(
                        (option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    {Boolean(touched.departments && errors.departments) && (
                      <FormHelperText>{errors.departments}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    select
                    type='select'
                    label='Employee Type'
                    {...getFieldProps('empType')}
                    onChange={formik.handleChange}
                    error={Boolean(touched.empType && errors.empType)}
                    helperText={touched.empType && errors.empType}>
                    {['Full Time', 'Contractor'].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='date'
                    label='Date of Joining'
                    {...getFieldProps('dateOfJoining')}
                    error={Boolean(
                      touched.dateOfJoining && errors.dateOfJoining
                    )}
                    helperText={touched.dateOfJoining && errors.dateOfJoining}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='number'
                    label='Total Experience (yrs)'
                    {...getFieldProps('totalExperience')}
                    error={Boolean(
                      touched.totalExperience && errors.totalExperience
                    )}
                    helperText={
                      touched.totalExperience && errors.totalExperience
                    }
                  />
                </Grid>
              </Grid>
              <Chip label='Misc Details' sx={{ mt: 3, mb: 2 }} />
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='text'
                    label='Emergency Contact'
                    {...getFieldProps('emergencyContactName')}
                    error={Boolean(
                      touched.emergencyContactName &&
                        errors.emergencyContactName
                    )}
                    helperText={
                      touched.emergencyContactName &&
                      errors.emergencyContactName
                    }
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='text'
                    label='Emergency Contact Phone'
                    {...getFieldProps('emergencyContactNumber')}
                    error={Boolean(
                      touched.emergencyContactNumber &&
                        errors.emergencyContactNumber
                    )}
                    helperText={
                      touched.emergencyContactNumber &&
                      errors.emergencyContactNumber
                    }
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='text'
                    label='Aadhar Number'
                    {...getFieldProps('aadharNumber')}
                    error={Boolean(touched.aadharNumber && errors.aadharNumber)}
                    helperText={touched.aadharNumber && errors.aadharNumber}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='text'
                    label='PAN'
                    {...getFieldProps('pan')}
                    error={Boolean(touched.pan && errors.pan)}
                    helperText={touched.pan && errors.pan}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    type='text'
                    label='UAN'
                    {...getFieldProps('uan')}
                    error={Boolean(touched.uan && errors.uan)}
                    helperText={touched.uan && errors.uan}
                  />
                </Grid>
                <Grid container item xs={6} />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  resetForm();
                  setSelectedUser(undefined);
                  setShowNewEmployee(false);
                  setShowDialog(false);
                }}>
                Cancel
              </Button>
              <Button type='submit' disabled={isSubmitting}>
                Save
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
