import { useContext, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { keyBy } from 'lodash';
import PropTypes from 'prop-types';

import {
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  MenuItem,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Stack,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  Checkbox,
  ListItemText
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DownloadIcon from '@mui/icons-material/Download';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import TableRowsIcon from '@mui/icons-material/TableRows';

import { visuallyHidden } from '@mui/utils';
import { formatHours } from '../../../utils/formatNumber';

import TimesheetRejectionDialog from './TimesheetRejectionDialog';

import UserContext from '../../../contexts/UserContext';
import { exportToExcel } from '../../../utils/excelUtils';

const MONTHS = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 }
];

// Get the server URL from environment variable
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

const headCells = [
  {
    id: 'entryDate',
    sortable: true,
    label: 'Date'
  },
  {
    id: 'userId',
    sortable: true,
    label: 'Resource'
  },
  {
    id: 'projectId',
    sortable: true,
    label: 'Project'
  },
  {
    id: 'hours',
    label: 'Hours'
  },
  {
    id: 'isBillable',
    label: 'Billable?'
  },
  {
    id: 'notes',
    label: 'Notes'
  }
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    numSelected,
    onSelectAllClick,
    rowCount
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            sortDirection={orderBy === headCell.id ? order : false}>
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell align='center'>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default function ManageTimesheets({
  userData,
  timeEntries,
  assignments
}) {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  let rowsToExcel = [];
  let finalRows = [];

  const exportHeaders = [
    [
      'Sr. No.',
      'Date',
      'Project',
      'Resource',
      'Task Details',
      'Hours',
      'Comments'
    ]
  ];

  const [usersToDisplay, setUsersToDisplay] = useState([]);

  const [projectNames, setProjectNames] = useState([]);

  const [usersByUserId, setUsersByUserId] = useState({});

  const [projectsById, setProjectsById] = useState({});

  const [rowsToDisplay, setRowsToDisplay] = useState([]);

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  const [showOnlyBillable, setShowOnlyBillable] = useState(true);

  const [showOnlyThisWeek, setOnlyThisWeek] = useState(false);

  const [showUnapprovedRequests, setshowUnapprovedRequests] = useState(true);

  const [selectedTimeEntries, setSelectedTimeEntries] = useState([]);

  const [selectedUser, setSelectedUser] = useState(0);

  const [selectedProjects, setSelectedProjects] = useState([]);

  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState('entryDate');

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [loadingApprove, setloadingApprove] = useState(false);

  const [selectedTimeEntry, setSelectedTimeEntry] = useState();

  useEffect(() => {
    if (!userData || !userData.length) return;

    setUsersToDisplay(userData);
    setUsersByUserId(keyBy(userData, '_id'));
  }, [userData]);

  useEffect(() => {
    if (!timeEntries || !timeEntries.length) return;

    setRowsToDisplay(timeEntries);
  }, [timeEntries]);

  useEffect(() => {
    if (!assignments || !assignments.length) return;

    setProjectsById(keyBy(assignments, '_id'));
    let projectNames = assignments.map((assgn) => assgn.projectName);
    projectNames = [...new Set(projectNames)];
    setProjectNames(projectNames);
    setSelectedProjects(projectNames);
  }, [assignments]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const approveTimeEntry = async (timeEntry) => {
    let response = await fetch(
      `${SERVER_URL}/api/timeEntries/${timeEntry._id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          approvedBy: `${user.firstName} ${user.lastName}`
        }),
        headers: { 'Content-Type': 'application/json' }
      }
    );

    response = await response.json();

    if (response.success) {
      timeEntry = response.data;
      enqueueSnackbar('Time entry approved', { variant: 'success' });
    } else {
      enqueueSnackbar(response.data, { variant: 'error' });
    }
  };

  const selectedApproveTimeEntry = async () => {
    setloadingApprove(true);
    let timeEntriesToPass = [];
    filterTimesheets(rowsToDisplay).forEach((element) => {
      if (selectedTimeEntries.includes(element._id)) {
        timeEntriesToPass = timeEntriesToPass.concat(element);
      }
    });

    const timeEntryIds = timeEntriesToPass.map((timeEntry) => timeEntry._id);
    let response = await fetch(`${SERVER_URL}/api/bulkTimeEntries`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        timeEntryIds,
        approvedBy: `${user.firstName} ${user.lastName}`
      })
    });

    response = await response.json();

    if (response.success) {
      enqueueSnackbar(
        'All selected time entries are approved. Please run the recalculation job to calculate billing',
        {
          variant: 'success'
        }
      );
      setSelectedTimeEntries([]);
    } else {
      enqueueSnackbar(response.data, { variant: 'error' });
    }
    setloadingApprove(false);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function filterTimesheets(rows) {
    rowsToExcel = [];
    finalRows = [];

    return rows.filter((val) => {
      let returnIt = false;
      let showWeekData = false;

      if (
        moment(val.entryDate).isBetween(
          moment().startOf('week'),
          moment().endOf('week')
        )
      ) {
        showWeekData = true;
      }

      if (
        (selectedMonth === 0 ||
          moment(val.entryDate).month() === selectedMonth - 1) &&
        (selectedUser === 0 || selectedUser === val.userId) &&
        (showOnlyBillable ? !!val.isBillable : true) &&
        selectedProjects.indexOf(projectsById[val.assignmentId].projectName) !==
          -1 &&
        (showOnlyThisWeek ? showWeekData : true) &&
        (showUnapprovedRequests ? val.isApproved === undefined : true)
      ) {
        returnIt = true;
        finalRows.push(val);
        rowsToExcel.push(val);
      }
      return returnIt;
    });
  }

  const handleClickCheckbox = (event, Id) => {
    const selectedIndex = selectedTimeEntries.indexOf(Id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTimeEntries, Id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTimeEntries.slice(1));
    } else if (selectedIndex === selectedTimeEntries.length - 1) {
      newSelected = newSelected.concat(selectedTimeEntries.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTimeEntries.slice(0, selectedIndex),
        selectedTimeEntries.slice(selectedIndex + 1)
      );
    }
    setSelectedTimeEntries(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filterTimesheets(rowsToDisplay).map((n) => n._id);
      setSelectedTimeEntries(newSelecteds);
    } else {
      setSelectedTimeEntries([]);
    }
  };

  const isSelected = (Id) => selectedTimeEntries.indexOf(Id) !== -1;

  return (
    <Card>
      <CardHeader title='Timesheets' />
      {rowsToDisplay.length === 0 ? (
        <Stack
          direction='column'
          justifyContent='space-around'
          alignItems='center'
          spacing={1}
          sx={{ mt: 4, mb: 2 }}>
          <Typography color='error'>
            <TableRowsIcon fontSize='large' />
          </Typography>
          <Typography color='error'>
            No timesheets submitted for approval
          </Typography>
        </Stack>
      ) : (
        <CardContent>
          <Stack direction='column' spacing={4}>
            <Stack direction='row' spacing={1} justifyContent='flex-end'>
              <FormGroup row>
                <FormControl sx={{ mr: 1, width: '10rem' }}>
                  <TextField
                    select
                    label='Select Month'
                    value={selectedMonth}
                    onChange={(e) => {
                      setSelectedMonth(e.target.value);
                    }}
                    size='small'>
                    <MenuItem value={0}>None</MenuItem>
                    {MONTHS.map((month) => (
                      <MenuItem key={month.value} value={month.value}>
                        {month.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>

                <FormControl sx={{ mr: 1, width: '10rem' }}>
                  <InputLabel id='projectsToFilter'>Projects</InputLabel>
                  <Select
                    labelId='projectsToFilter'
                    multiple
                    value={selectedProjects}
                    onChange={(evt) => {
                      const {
                        target: { value }
                      } = evt;
                      setSelectedProjects(
                        // On autofill we get a stringified value.
                        typeof value === 'string' ? value.split(',') : value
                      );
                    }}
                    input={
                      <OutlinedInput label='Projects' sx={{ height: 40 }} />
                    }
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}>
                    {projectNames.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                          checked={selectedProjects.indexOf(name) > -1}
                        />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ mr: 1, width: '10rem' }}>
                  <TextField
                    select
                    label='Select Employee'
                    value={selectedUser}
                    onChange={(e) => {
                      setSelectedUser(e.target.value);
                    }}
                    size='small'>
                    <MenuItem value={0}>All</MenuItem>
                    {usersToDisplay
                      .filter((user) => user.isActive)
                      .map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                          {`${user.firstName} ${user.lastName} (${user.empId})`}
                        </MenuItem>
                      ))}
                  </TextField>
                </FormControl>

                <FormControlLabel
                  control={
                    <Switch
                      onChange={() => {
                        setshowUnapprovedRequests(!showUnapprovedRequests);
                      }}
                      checked={showUnapprovedRequests}
                    />
                  }
                  label='Unapproved Only'
                />

                <FormControlLabel
                  control={
                    <Switch
                      onChange={() => {
                        setOnlyThisWeek(!showOnlyThisWeek);
                      }}
                      checked={showOnlyThisWeek}
                    />
                  }
                  label='This Week'
                />
                <FormControlLabel
                  control={
                    <Switch
                      onChange={() => {
                        setShowOnlyBillable(!showOnlyBillable);
                      }}
                      checked={showOnlyBillable}
                    />
                  }
                  label='Billable Only'
                />
              </FormGroup>
            </Stack>

            <Stack direction='row' justifyContent='flex-end'>
              <Tooltip title='Approve Selected Entries'>
                <LoadingButton
                  variant='outlined'
                  onClick={selectedApproveTimeEntry}
                  startIcon={<ThumbUpOffAltIcon />}
                  loadingPosition='center'
                  loading={loadingApprove}>
                  Approve
                </LoadingButton>
              </Tooltip>

              <Tooltip title='Download as Excel'>
                <Button
                  sx={{ ml: 2 }}
                  variant='outlined'
                  startIcon={<DownloadIcon />}
                  onClick={() => {
                    exportToExcel(rowsToExcel, exportHeaders, usersByUserId);
                  }}>
                  Download
                </Button>
              </Tooltip>
            </Stack>
            <Box>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: '100%' }}
                  size='small'
                  aria-label='a dense table'>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    numSelected={selectedTimeEntries.length}
                    rowCount={filterTimesheets(rowsToDisplay).length}
                  />

                  <TableBody>
                    {stableSort(
                      filterTimesheets(rowsToDisplay),
                      getComparator(order, orderBy)
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row._id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            key={row._id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 }
                            }}>
                            <TableCell
                              align='center'
                              role='checkbox'
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              selected={isItemSelected}>
                              <Checkbox
                                disabled={row.isApproved || row.isRejected}
                                onClick={(event) =>
                                  handleClickCheckbox(event, row._id)
                                }
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell>
                            <TableCell align='center' width='10%'>
                              {moment(row.entryDate).format('DD MMM YY')}
                            </TableCell>
                            <TableCell align='center' width='10%'>
                              {usersByUserId[row.userId].firstName}
                            </TableCell>
                            <TableCell align='center' width='10%'>
                              {projectsById[row.assignmentId].projectName}
                            </TableCell>
                            <TableCell align='center' width='10%'>
                              {formatHours(row.hours)}
                            </TableCell>
                            <TableCell align='center' width='5%'>
                              {row.isBillable ? (
                                <CheckIcon color='success' />
                              ) : (
                                <CloseIcon color='error' />
                              )}
                            </TableCell>
                            <TableCell align='left' width='50%'>
                              {row.notes}
                            </TableCell>
                            <TableCell align='center' width='5%'>
                              <Stack
                                direction='row'
                                spacing={1}
                                justifyContent='center'>
                                {row.isApproved ? (
                                  <Tooltip title='Approved'>
                                    <ThumbUpOffAltIcon color='success' />
                                  </Tooltip>
                                ) : (
                                  !row.isRejected && (
                                    <Button
                                      variant='contained'
                                      color='success'
                                      onClick={() => {
                                        approveTimeEntry(row);
                                      }}>
                                      Approve
                                    </Button>
                                  )
                                )}

                                {row.isRejected ? (
                                  <Tooltip
                                    title={`Rejected. ${row.rejectionReason}`}>
                                    <ThumbDownAltOutlinedIcon color='error' />
                                  </Tooltip>
                                ) : (
                                  !row.isApproved && (
                                    <Button
                                      variant='contained'
                                      color='error'
                                      onClick={() => {
                                        setSelectedTimeEntry(row);
                                      }}>
                                      Reject
                                    </Button>
                                  )
                                )}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component='div'
                count={finalRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />

              {selectedTimeEntry && (
                <TimesheetRejectionDialog
                  timeEntry={selectedTimeEntry}
                  setSelectedTimeEntry={setSelectedTimeEntry}
                  rejectedByUser={user}
                />
              )}
            </Box>
          </Stack>
        </CardContent>
      )}
    </Card>
  );
}
