import { useState } from 'react';
import { useSnackbar } from 'notistack';

import { Stack, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { LoadingButton } from '@mui/lab';

const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function RecalculateTimeEntries() {
  const { enqueueSnackbar } = useSnackbar();

  const [isRecalculating, setIsRecalculating] = useState(false);

  const handleRecalculateEntries = async () => {
    setIsRecalculating(true);

    const endpoint = `${SERVER_URL}/api/data/recalc`;

    let response = await fetch(endpoint, {
      method: 'GET'
    });
    response = await response.json();

    if (response.success) {
      enqueueSnackbar('Time Entries Recalculated', {
        variant: 'success'
      });
    } else {
      enqueueSnackbar(response.data, { variant: 'error' });
    }

    setIsRecalculating(false);
  };

  return (
    <Stack>
      <Typography sx={{ mb: 2 }}>
        Manually recalculating time entries is needed after loading time entries
        from an external document. Click the button below to manually
        recalculate time entries.
      </Typography>
      <LoadingButton
        variant='contained'
        onClick={() => {
          handleRecalculateEntries();
        }}
        endIcon={<SyncIcon />}
        loadingPosition='end'
        loading={isRecalculating}>
        Recalculate Time Entries
      </LoadingButton>
    </Stack>
  );
}
