import { useState, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import Cookies from 'js-cookie';

import {
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

import { prefetchData } from '../../../utils/networkUtils';

import UserContext from '../../../contexts/UserContext';
import AppDataContext from '../../../contexts/AppContext';

// Get the server URL from environment variable
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function LoginForm() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const { enqueueSnackbar } = useSnackbar();

  const { setUser } = useContext(UserContext);
  const { setAppData } = useContext(AppDataContext);

  const [showPassword, setShowPassword] = useState(false);

  const [showLoginForm, setShowLoginForm] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    const authUserId = Cookies.get('auth-cookie');

    if (!authUserId) {
      setIsLoading(false);
      setShowLoginForm(true);
    }

    let userResponse = await fetch(`${SERVER_URL}/api/users/${authUserId}`);
    userResponse = await userResponse.json();

    if (userResponse.success) {
      setShowLoginForm(false);
    } else {
      setShowLoginForm(true);
    }

    setIsLoading(false);
  }, []);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      let loginResponse = await fetch(
        `${SERVER_URL}/api/login?email=${values.email}&password=${values.password}`
      );
      loginResponse = await loginResponse.json();

      if (loginResponse.success) {
        if (values.remember) {
          Cookies.set('auth-cookie', loginResponse.data._id);
        }

        setUser(loginResponse.data);

        await prefetchData(
          (response) => {
            setAppData(response);
          },
          (response) => {
            const { data } = response;
            enqueueSnackbar(data, { variant: 'error' });
          }
        );

        // Check if there is a start url param
        const startUrl = searchParams.get('startUrl');

        if (
          loginResponse.data.permissions &&
          loginResponse.data.permissions.pageAccess &&
          loginResponse.data.permissions.pageAccess.dashboard === true
        ) {
          navigate(startUrl || '/dashboard/app', { replace: true });
        } else {
          navigate('/dashboard/timesheets');
        }
      } else {
        enqueueSnackbar(loginResponse.data, { variant: 'error' });
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return isLoading ? (
    <Stack sx={{ mb: 5 }}>
      <Typography variant='h4' gutterBottom>
        Signing in to TimeKeeper
      </Typography>
      <Typography sx={{ color: 'text.secondary' }}>
        Logging you in...
      </Typography>
    </Stack>
  ) : (
    showLoginForm && (
      <Stack sx={{ mb: 5 }}>
        <Typography variant='h4' gutterBottom>
          Sign in to TimeKeeper
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          Enter your details below.
        </Typography>

        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                autoComplete='username'
                type='email'
                label='Email address'
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />

              <TextField
                fullWidth
                autoComplete='current-password'
                type={showPassword ? 'text' : 'password'}
                label='Password'
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={handleShowPassword} edge='end'>
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Stack>

            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              sx={{ my: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...getFieldProps('remember')}
                    checked={values.remember}
                  />
                }
                label='Remember me'
              />
            </Stack>

            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              loading={isSubmitting}>
              Login
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Stack>
    )
  );
}
