import { useContext } from 'react';

import { Grid, Container } from '@mui/material';

import Page from '../../utils/views/Page';

import AppEmployeeCount from './components/AppEmployeeCount';
import AppUnallocatedResources from './components/AppUnallocatedResources';
import AppActiveProjects from './components/AppActiveProjects';
import AppCurrentRevenue from './components/AppCurrentRevenue';
import AppUpcomingLeaves from './components/AppUpcomingLeaves';
import AppHoursBilled from './components/AppHoursBilled';
import ManageTimesheets from '../Timesheets/components/ManageTimesheets';

import AppDataContext from '../../contexts/AppContext';

import './css/styles.css';

export default function DashboardApp() {
  const { appData } = useContext(AppDataContext);

  return (
    <Page title='Dashboard'>
      <Container maxWidth='xl'>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppCurrentRevenue
              projects={appData.projects.filter(
                (prj) => prj.type !== 'Internal'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppActiveProjects projects={appData.projects} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppEmployeeCount employees={appData.users} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppUnallocatedResources employees={appData.users} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppHoursBilled
              projects={appData.projects.filter(
                (prj) => prj.type !== 'Internal'
              )}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppUpcomingLeaves leaves={appData.leaves} />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <ManageTimesheets
              userData={appData.users}
              assignments={appData.assignments}
              timeEntries={appData.timeEntries}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
