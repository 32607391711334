import { useEffect, useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import isWeekend from 'date-fns/isWeekend';
import moment from 'moment';

import { Box, Typography, Grid, Tab, Tabs, TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';

import TimesheetTable from './components/TimesheetTable';
import ManageTimesheets from './components/ManageTimesheets';
import EmployeeTimesheet from './components/EmployeeTimesheet';

import UserContext from '../../contexts/UserContext';
import AppDataContext from '../../contexts/AppContext';

// For tabs and tab for personal and dependent users
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component='span'>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

// Get the server URL from environment variable
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function Timesheets() {
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);

  const [value, setValue] = useState(0);

  const [projectValues, setProjectValues] = useState([]);

  const [timesheetRows, setTimesheetRows] = useState([]);

  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(async () => {
    let prjResponse = await fetch(
      `${SERVER_URL}/api/users/${user._id}/projects`
    );
    prjResponse = await prjResponse.json();

    if (prjResponse.success) {
      setProjectValues(
        prjResponse.data.map((assgn) => ({
          value: assgn._id,
          label: assgn.projectName,
          isOngoing: assgn.prjStatus === 'Ongoing'
        }))
      );
    } else {
      enqueueSnackbar(prjResponse.data, { variant: 'error' });
    }

    await fetchTimesheetRecords(moment().format());
  }, []);

  const fetchTimesheetRecords = async (dateValue) => {
    let response = await fetch(
      `${SERVER_URL}/api/users/${user._id}/timeEntries?date=${moment(
        dateValue
      ).format()}`
    );
    response = await response.json();

    if (response.success) {
      setTimesheetRows(response.data);
    } else {
      enqueueSnackbar(response.data, { variant: 'error' });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'>
          <Tab label='Enter Time Entries' {...a11yProps(0)} />
          <Tab label='My Time Entries ' {...a11yProps(1)} />
          <Tab label='Time Entries for Approval' {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container spacing={1} direction='row'>
          <Grid item xs={12} md={4} lg={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                orientation='landscape'
                openTo='day'
                value={selectedDate}
                shouldDisableDate={isWeekend}
                onChange={(newValue) => {
                  setTimesheetRows([]);
                  setSelectedDate(newValue);
                  fetchTimesheetRecords(newValue);
                }}
                displayStaticWrapperAs='desktop'
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} md={8} lg={8}>
            <TimesheetTable
              projectList={projectValues}
              rowsToDisplay={timesheetRows}
              entryDate={moment(selectedDate).format()}
            />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {appData.users && appData.timeEntries && (
          <EmployeeTimesheet
            assignments={appData.assignments.filter(
              (row) => user._id === row.userId
            )}
            timeEntries={appData.timeEntries.filter(
              (row) => user._id === row.userId
            )}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {appData.users && appData.assignments && appData.timeEntries && (
          <ManageTimesheets
            userData={appData.users.filter((row) =>
              user.reportingUsers.includes(row._id)
            )}
            assignments={appData.assignments.filter((row) =>
              user.reportingUsers.includes(row.userId)
            )}
            timeEntries={appData.timeEntries.filter((row) =>
              user.reportingUsers.includes(row.userId)
            )}
          />
        )}
      </TabPanel>
    </Box>
  );
}
