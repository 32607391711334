import { useSnackbar } from 'notistack';

import { Typography, Stack, Button } from '@mui/material';

const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export default function ResetPasswords({ appData }) {
  const { enqueueSnackbar } = useSnackbar();

  const handleResetPassword = async (user) => {
    const endpoint = `${SERVER_URL}/api/users/${user._id}`;
    const method = 'PATCH';

    user.password = 'smijay@123';

    let response = await fetch(endpoint, {
      method,
      body: JSON.stringify(user),
      headers: { 'Content-Type': 'application/json' }
    });
    response = await response.json();

    if (response.success) {
      enqueueSnackbar('Password reset successfully', {
        variant: 'success'
      });
    } else {
      enqueueSnackbar(response.data, { variant: 'error' });
    }
  };

  return (
    <Stack spacing={2}>
      {appData.users.map((user) => (
        <Stack key={user._id} direction='row' spacing={2}>
          <Typography sx={{ width: '40%' }}>
            {`${user.firstName} ${user.lastName} (${user.empId})`}
          </Typography>
          <Typography>
            <Button
              variant='contained'
              onClick={() => {
                handleResetPassword(user);
              }}>
              Reset Password
            </Button>
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}
